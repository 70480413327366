import { PayloadAction } from '@reduxjs/toolkit'
import { TReportingFilters } from '@unfoldrtech/portal-mic/dist/src/models/types'

const setFilterValue = (
  state: TReportingFilters,
  action: PayloadAction<{
    filterValue?: number
  }>
) => {
  state.filterValue = action.payload.filterValue || undefined
}

export default setFilterValue
