import React, { PropsWithChildren, ReactNode, useRef, useState } from 'react'
import styled from 'styled-components'
import { IButtonProps, StyledButton } from './StyledButton'
import { Container } from '../containers'
import SvgIconArrowDownSmall from '../icons/IconArrowDownSmall'
import SvgIconArrowUpSmall from '../icons/IconArrowUpSmall'
import { useClickAwayListener } from '../../../hooks/custom/useClickAwayListener'
import { StyledDropdownItem } from '../elements/bootstrap/StyledDropdownItem'
import { TContainerProps } from '../styles'

export type TMenuItem = {
  label: ReactNode
  icon?: ReactNode
  onClick: () => void
}

export type TStyledDropDownButtonV2Props = IButtonProps & {
  menuItems: TMenuItem[]
  itemsZIndex?: TContainerProps['zIndex']
  'data-testid'?: string
}

const DropDownItem = styled(StyledDropdownItem)`
  padding: var(--gutter-1x) var(--gutter-2x);
`

export const StyledDropdownButtonV2 = ({
  children,
  menuItems,
  itemsZIndex,
  'data-testid': dataTestId,
  ...buttonProps
}: PropsWithChildren<TStyledDropDownButtonV2Props>) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [openMenu, setOpenMenu] = useState(false)

  useClickAwayListener(containerRef, () => {
    setOpenMenu(false)
  })

  const handleToggleMenu = () => {
    setOpenMenu((prev) => !prev)
  }

  return (
    <Container ref={containerRef} position="relative">
      <StyledButton
        {...buttonProps}
        onClick={handleToggleMenu}
        data-testid={dataTestId ?? 'dropdown-button'}
      >
        {children}{' '}
        {openMenu ? (
          <SvgIconArrowUpSmall data-testid="open-icon" />
        ) : (
          <SvgIconArrowDownSmall data-testid="close-icon" />
        )}
      </StyledButton>

      {openMenu && (
        <Container
          data-testid="dropdown-container"
          position="absolute"
          background="var(--background-color-light)"
          zIndex={itemsZIndex}
          top="4px"
          marginTop="var(--gutter-mini)"
          border={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'var(--border-color-default)',
            radius: 'var(--gutter-mini)',
          }}
        >
          {menuItems.map(({ label, icon, onClick }, index) => (
            <DropDownItem
              data-testid={`dropdown-option-${index}`}
              key={String(label)}
              onClick={(e) => {
                e.stopPropagation()
                onClick()
                setOpenMenu(false)
              }}
            >
              <Container display="flex" gap="var(--gutter-1x)">
                <Container>{icon}</Container>
                <Container>{label}</Container>
              </Container>
            </DropDownItem>
          ))}
        </Container>
      )}
    </Container>
  )
}
