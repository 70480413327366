import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { StyledInput2, Container, StyledButton, Text } from '../../Global'
import I18NText from '../../I18NText'
import {
  selectReportingFilters,
  setName,
} from '../../../store/reportingFilters'
import { setRefreshTable } from '../../../store/refreshTable'
import { setRefreshChart } from '../../../store/refreshChart'

export interface INameFilterForm {
  name?: string
}

export const NameFilter = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { name: reportingName } = useSelector(selectReportingFilters)
  const [nameFilterModal, setNameFilterModal] = useState<INameFilterForm>({
    name: reportingName,
  })

  const methods = useForm<INameFilterForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
  } = methods

  const onSubmit = async (data: INameFilterForm) => {
    dispatch(setName({ name: data.name }))
    dispatch(setRefreshTable({ refreshTable: true }))
    dispatch(setRefreshChart({ refreshChart: true }))
    reset()
  }

  return (
    <>
      <Container paddingLeft="var(--gutter-1x)" flex="1">
        <Text fontWeight={500}>
          <I18NText id="reporting.filter.select.name" preset="h6" />
        </Text>
      </Container>
      <Container paddingTop="var(--gutter-2x)" paddingBottom="var(--gutter-1x)">
        <Controller
          name="name"
          control={control}
          defaultValue={nameFilterModal.name}
          render={({ field }) => (
            <StyledInput2
              id="name"
              field={field}
              value={nameFilterModal.name}
              error={errors.name}
              label={intl.formatMessage({
                id: 'reporting.filter.select.name.placeholder',
              })}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNameFilterModal({
                  name: event.target.value,
                })
                field.onChange(event)
              }}
              containerSize={{ width: '100%' }}
              data-testid="reporting-filter-name-input"
            />
          )}
          rules={{
            maxLength: {
              value: 255,
              message: intl.formatMessage({
                id: 'retailer.errors.max.255',
              }),
            },
          }}
        />
      </Container>
      <Container display="flex" justifyContent="right">
        <StyledButton
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
          width="100px"
          height="40px"
        >
          <Container
            display="grid"
            height="calc(40px - var(--gutter-2x))"
            padding="var(--gutter-mini)"
          >
            <I18NText
              id="reporting.filter.apply"
              preset="cta-sm"
              fontSize="var(--font-size-ps)"
              data-testid="reporting-filter-name-apply-button"
            />
          </Container>
        </StyledButton>
      </Container>
    </>
  )
}
