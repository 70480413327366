import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError } from '@unfoldrtech/portal-mic'
import { generateCorrelationId, getTimezone } from '../utils/helpers'
import {
  TDAPositionsReportChartFilters,
  TPPAdvertiserId,
} from '../models/types'
import {
  EReportingTimeWindow,
  IDAReportingPositionsChartResponse,
} from '../models'
import { coreAPIV3 } from '../services/coreAPIV3'
import { ETimeBucketType } from '../models/core'

export default function useGetAdvertiserReportingDAPositionsChartV3({
  advertiserId,
  retailerId,
  campaignId,
  adGroupId,
  timeWindow,
  startDate,
  endDate,
  tzZone,
  aggregationType,
  filterBy,
  filterOperation,
  filterValue,
  name,
  status,
  enabled = true,
  suspense = true,
}: {
  enabled?: boolean
  suspense?: boolean
} & TDAPositionsReportChartFilters &
  TPPAdvertiserId) {
  const queryKeyName = `getAdvertiserReportingDAPositionsChart_${advertiserId}_${retailerId}`

  return useQuery<
    AxiosResponse<IDAReportingPositionsChartResponse>,
    AxiosError<IError>
  >(
    [queryKeyName, { start: startDate, end: endDate }],
    () => {
      return coreAPIV3
        .advertiserReporting()
        .getAdvertiserPositionsReportChart(
          advertiserId,
          generateCorrelationId(),
          retailerId,
          campaignId,
          adGroupId,
          timeWindow as any as EReportingTimeWindow,
          startDate,
          endDate,
          tzZone || getTimezone(),
          aggregationType as ETimeBucketType,
          filterBy,
          filterOperation,
          filterValue,
          name,
          status
        )
    },
    { suspense, enabled }
  )
}
