import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Container, StyledButton, Text, StyledSelect2 } from '../../Global'
import I18NText from '../../I18NText'
import { EChannelTypeGlobal } from '../../../models/core'
import { sanitizeDataTestId } from '../../../utils'
import {
  selectReportingFilters,
  setChannelType,
} from '../../../store/reportingFilters'
import { setRefreshTable } from '../../../store/refreshTable'
import { setRefreshChart } from '../../../store/refreshChart'

export interface IChannelFilterForm {
  channel?: EChannelTypeGlobal
}

// Deprecated, leaving here as we are unaware if in the future we will want to add channel filters
export const ChannelFilter = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { channel: reportingChannelType } = useSelector(selectReportingFilters)
  const [channelFilterModal, setChannelFilterModal] =
    useState<IChannelFilterForm>({
      channel: reportingChannelType,
    })

  const methods = useForm<IChannelFilterForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = methods

  const onSubmit = async (data: IChannelFilterForm) => {
    dispatch(setChannelType({ channel: data.channel }))
    dispatch(setRefreshTable({ refreshTable: true }))
    dispatch(setRefreshChart({ refreshChart: true }))
    reset()
  }

  return (
    <Container width="240px">
      <Container paddingLeft="var(--gutter-1x)" flex="1">
        <Text preset="formfield-lg">
          <I18NText id="reporting.filter.select.channel" />
        </Text>
      </Container>
      <Container paddingTop="var(--gutter-2x)" paddingBottom="var(--gutter-1x)">
        <Controller
          name="channel"
          control={control}
          defaultValue={channelFilterModal.channel}
          render={({ field }) => (
            <StyledSelect2
              id="channel"
              {...field}
              value={channelFilterModal.channel}
              padding="var(--gutter-1x)"
              error={errors.channel}
              options={[
                {
                  id: 1,
                  label: intl.formatMessage({
                    id: EChannelTypeGlobal.SponsoredProduct.toLowerCase(),
                  }),
                  value: EChannelTypeGlobal.SponsoredProduct,
                  'data-testid': sanitizeDataTestId(
                    `channel-option-${EChannelTypeGlobal.SponsoredProduct}`
                  ),
                },
                {
                  id: 2,
                  label: intl.formatMessage({
                    id: EChannelTypeGlobal.DisplayAd.toLowerCase(),
                  }),
                  value: EChannelTypeGlobal.DisplayAd,
                  'data-testid': sanitizeDataTestId(
                    `channel-option-${EChannelTypeGlobal.DisplayAd}`
                  ),
                },
                {
                  id: 3,
                  label: intl.formatMessage({
                    id: EChannelTypeGlobal.DigitalOutOfHome.toLowerCase(),
                  }),
                  value: EChannelTypeGlobal.DigitalOutOfHome,
                  'data-testid': sanitizeDataTestId(
                    `channel-option-${EChannelTypeGlobal.DigitalOutOfHome}`
                  ),
                },
              ]}
              onChange={(value) => {
                setChannelFilterModal({ channel: value as EChannelTypeGlobal })
                setValue('channel', value as EChannelTypeGlobal)
                trigger('channel')
              }}
              data-testid="reporting-filter-channel-select"
            />
          )}
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: 'error.required',
              }),
            },
          }}
        />
      </Container>
      <Container display="flex" justifyContent="right">
        <StyledButton
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
          width="100px"
        >
          <I18NText
            id="reporting.filter.apply"
            preset="cta-sm"
            fontSize="var(--font-size-ps)"
            data-testid="reporting-filter-channel-apply-button"
          />
        </StyledButton>
      </Container>
    </Container>
  )
}
