import styled, { CSSProperties } from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import { Size, Border, FontStyle } from '../../styles/mixins'

export const StyledModal = styled(Modal).withConfig({
  shouldForwardProp: (prop) =>
    ![
      'borderLeft',
      'borderRight',
      'borderTop',
      'borderBottom',
      'border',
      'backgroundColor',
      'textAlign',
    ].includes(prop as string),
})<
  Pick<CSSProperties, 'background' | 'backgroundColor' | 'border' | 'textAlign'>
>`
  && .modal-content {
    background: ${(props) => props.background || ''};
    background-color: ${(props) => props.backgroundColor || ''};
    border: ${(props) => props.border || ''};
    text-align: ${(props) => props.textAlign || ''};
  }

  && .modal-header {
    padding: 0;
    ${Size({
      width: '100%',
    })}
    ${Border({
      border: { borderBottomWidth: 0 },
    })}

    & .btn-close {
      padding: var(--gutter-3x);
      margin: 0;
      margin-bottom: auto;
    }
    & .modal-title {
      padding: var(--gutter-5x);
      padding-bottom: 0px;

      & span {
        ${FontStyle({
          fontSize: 'var(--font-size-h2)',
          lineHeight: 'calc(var(--font-size-h2) + 2px)',
        })}
      }
    }
  }

  && .modal-body {
    padding: 0 var(--gutter-5x) 0 var(--gutter-5x);
  }

  && .modal-footer {
    border-top: 0;
    padding: var(--gutter-5x);
    > * {
      margin: 0px;
    }
  }

  && .custom-size-position {
    ${Size({
      width: '600px',
      maxWidth: '60%',
    })}

    & .modal-header {
      border: none;
      padding: none;

      & .modal-title span.modal-subtitle {
        font-size: var(--font-size-sm);
      }
    }
    & .modal-content {
      padding: 0;
    }
    & .modal-footer {
      border: none;
      padding: none;
    }
  }

  && .custom-size-confirm {
    ${Size({
      width: '520px',
      maxWidth: '60%',
    })}

    & .modal-header {
      border: none;
      padding: none;
    }
    & .modal-content {
      padding: 0;
    }
    & .modal-footer {
      border: none;
      padding: none;
    }
  }

  && .custom-size-fp {
    ${Size({
      width: '350px',
    })}
  }

  && .custom-size-create-adgroup {
    ${Size({
      width: '1000px',
      maxWidth: 'unset',
    })}
    & .modal-content {
      & .modal-footer {
        ${Size({
          width: '999px',
          maxWidth: 'unset',
        })}
      }
    }
  }

  && .custom-size-campaign-create {
    ${Size({
      width: '70%',
      maxWidth: 'unset',
    })}
  }

  && .custom-size-confirm-update-positions-adgroup {
    ${Size({
      width: '520px',
      maxWidth: 'unset',
    })}
  }

  && .custom-size-ad-group-sp {
    & .modal-body {
      ${Size({
        minHeight: '472px',
      })}
    }
  }

  && .custom-size-ad-group {
    & .modal-body {
      ${Size({
        minHeight: '472px',
      })}
    }
  }

  && .custom-size-ad-group-edit {
    & .modal-body {
      ${Size({
        minHeight: '200px',
      })}
    }
  }

  && .custom-size-history-creative {
    ${Size({
      width: '700px',
    })}
    & .modal-header {
      & .modal-title {
        width: inherit;
        padding-right: 0;
      }
      & .btn-close {
        padding: var(--gutter-3x);
        padding-left: var(--gutter-mini);
      }
    }
  }

  && .custom-size-dooh-inventory-edit {
    & .modal-body {
      ${Size({
        minHeight: '350px',
      })}
    }

    & .modal-header {
      border: none;
      padding: none;

      & .modal-title span.modal-subtitle {
        font-size: var(--font-size-sm);
      }
    }
    & .modal-content {
      padding: 0;
    }
    & .modal-footer {
      border: none;
      padding: none;
    }
  }

  custom-size-ad-agroup-da-edit && .custom-size-user-invite {
    ${Size({
      width: '680px',
      maxWidth: 'unset',
    })}
  }

  && .custom-size-ad-data-template {
    ${Size({
      width: '740px',
      maxWidth: 'unset',
    })}

    & .modal-body {
      padding: 0 var(--gutter-4x) 0 var(--gutter-4x);
    }
  }

  && .toast-container {
    ${Size({
      maxWidth: 'unset',
    })}
  }

  && .keyword-modal {
    ${Size({
      width: '1040px',
      maxWidth: 'unset',
    })}

    & .modal-body {
      padding: 0 var(--gutter-5x) 0 var(--gutter-5x);
    }

    & .modal-header {
      padding: var(--gutter-5x);
      padding-top: var(--gutter-3x);
      padding-bottom: 0;
    }

    & .modal-footer {
      padding-top: var(--gutter-1x);
    }
  }

  && .mfa-sign-up-modal {
    ${Size({
      width: '560px',
      maxWidth: 'unset',
    })}

    & .modal-body {
      padding: 0 var(--gutter-5x) 0 var(--gutter-5x);
    }

    & .modal-header {
      padding: var(--gutter-5x);
      padding-top: var(--gutter-5x);
      padding-bottom: 0;
    }

    & .modal-footer {
      padding-top: var(--gutter-1x);
    }
  }

  && .mfa-login-modal {
    ${Size({
      width: '560px',
      maxWidth: 'unset',
    })}

    & .modal-body {
      padding: 0 var(--gutter-7x) 0 var(--gutter-7x);
    }

    & .modal-header {
      padding: var(--gutter-7x);
      padding-top: var(--gutter-5x);
      padding-bottom: 0;
    }

    & .modal-footer {
      padding-top: var(--gutter-1x);
      padding-left: var(--gutter-7x);
      padding-right: var(--gutter-7x);
    }
  }

  && .category-modal {
    ${Size({
      width: '740px',
      maxWidth: 'unset',
    })}

    & .modal-body {
      padding: 0 var(--gutter-5x) 0 var(--gutter-5x);
    }

    & .modal-header {
      padding: var(--gutter-5x);
      padding-top: var(--gutter-3x);
      padding-bottom: 0;
    }

    & .modal-footer {
      padding-top: 0;
    }
  }

  && .product-modal {
    ${Size({
      width: '660px',
      maxWidth: 'unset',
    })}

    & .modal-body {
      padding: 0 var(--gutter-5x) 0 var(--gutter-5x);
    }

    & .modal-header {
      padding: var(--gutter-5x);
      padding-top: var(--gutter-3x);
      padding-bottom: 0;
    }

    & .modal-footer {
      padding-top: 0;
    }
  }

  && .manage-products-modal {
    ${Size({
      width: '900px',
      maxWidth: 'unset',
    })}

    & .modal-header {
      padding: var(--gutter-2x);
      padding-left: var(--gutter-5x);
      padding-top: var(--gutter-3x);
      padding-bottom: 0;
    }
  }

  && .custom-category-attribution-bulk {
    & .modal-title {
      padding: var(--gutter-5x);
      padding-right: var(--gutter-2x);
      padding-bottom: 0px;

      & span {
        ${FontStyle({
          fontSize: 'var(--font-size-h3)',
          lineHeight: 'calc(var(--font-size-h3) + 2px)',
        })}
      }
    }
  }
`
