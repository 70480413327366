import React, { ReactNode } from 'react'
import { Container, EllipsisContainer } from '../Global/containers'
import { StyledBadge } from '../Global/elements/bootstrap'
import { Text } from '../Global/fonts'
import { TooltipWrapper } from '../Global/tooltips'
import { TSizeMixin } from '../Global'

type TPillData = {
  title: string
}

export type TStyledPillProps = {
  isVisible: boolean
  pillId: string
  data: TPillData
  prefix?: ReactNode | ReactNode[]
  suffix?: ReactNode | ReactNode[]
  onClick?: (props: Pick<TStyledPillProps, 'pillId' | 'data'>) => void
  onClear?: (props: Pick<TStyledPillProps, 'pillId' | 'isVisible'>) => void
  badgeSize?: TSizeMixin
  badgeBackground?: string
  dataTestId?: string
  tooltipText?: string
  showTooltip?: boolean
}

function StyledPill({
  isVisible,
  pillId,
  data,
  prefix,
  suffix,
  onClear,
  onClick,
  badgeSize,
  badgeBackground,
  dataTestId,
  tooltipText,
  showTooltip = true,
}: TStyledPillProps) {
  const handleOnClear = () => {
    if (onClear) {
      onClear({ isVisible, pillId })
    }
  }

  if (!isVisible) {
    return null
  }

  return (
    <StyledBadge
      bg="dark"
      text="light"
      backgroundColor={badgeBackground}
      pill
      cursor="default"
      data-testid={dataTestId}
      {...badgeSize}
    >
      <Container
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Container flex="1" minWidth="0">
          <TooltipWrapper
            tooltip={tooltipText}
            showTooltip={showTooltip}
            whiteSpace="pre-line"
            textAlign="left"
            maxWidth="fit-content"
          >
            <Container
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {prefix && (
                <Container
                  onClick={handleOnClear}
                  paddingRight="var(--gutter-1x)"
                >
                  {prefix}
                </Container>
              )}
              <EllipsisContainer
                onClick={() => {
                  if (onClick) {
                    onClick({ data, pillId })
                  }
                }}
                color="var(--font-color-light)"
              >
                <Text preset="paragraph-regular" fontSize="var(--font-size-ps)">
                  {data.title}
                </Text>
              </EllipsisContainer>
              {suffix && (
                <Container
                  onClick={handleOnClear}
                  padding="var(--gutter-1x)"
                  cursor="pointer"
                >
                  {suffix}
                </Container>
              )}
            </Container>
          </TooltipWrapper>
        </Container>
      </Container>
    </StyledBadge>
  )
}

export default StyledPill
