import React from 'react'
import { useTable, useBlockLayout, Column } from 'react-table'
import { withSize, SizeMeProps } from 'react-sizeme'
import { FixedSizeList } from 'react-window'

import { TABLE_COLUMN_WIDTH, TABLE_HEIGHT, TABLE_ROW_HEIGHT } from '../table'
import { Container } from '../containers'
import { VirtualTableStyles } from './VirtualTableStyles'
import I18NText from '../../I18NText'

const ReactVirtualTable = <T extends object>({
  columns,
  data,
  size,
  isReport,
  enableSeparator,
  placeholderKey,
  testId,
}: {
  columns: Array<Column<T>>
  data: Array<T>
  isReport?: boolean
  enableSeparator?: boolean
  placeholderKey?: string
  testId?: string
} & SizeMeProps) => {
  const defaultColumn = React.useMemo(
    () => ({
      width: size.width
        ? Math.floor(size.width / columns.length)
        : TABLE_COLUMN_WIDTH,
    }),
    []
  )

  let totalWidth = 0
  columns.forEach((column) => {
    if (column?.width && column?.width?.toString().includes('px')) {
      const columnWidth = parseInt(
        column.width.toString().replace('px', ''),
        10
      )
      totalWidth += columnWidth
    }
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: isReport ? undefined : defaultColumn,
    },
    useBlockLayout
  )

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)

      return (
        <Container
          {...row.getRowProps({
            style,
          })}
          className="tr"
          width={isReport ? 'fit-content !important' : '100%'}
          borderBottom={
            enableSeparator
              ? {
                  width: '1px',
                  color: 'var(--border-color-default)',
                  style: 'solid',
                }
              : undefined
          }
        >
          {row.cells.map((cell, cellIndex) => {
            return (
              <Container
                {...cell.getCellProps()}
                className="td"
                padding={isReport ? '0 var(--gutter-2x)' : undefined}
                width={cell.column.width || undefined}
                data-testid={`report-table-row-${index}-cell-${cellIndex}`}
              >
                {cell.render('Cell')}
              </Container>
            )
          })}
        </Container>
      )
    },
    [prepareRow, rows]
  )

  // Render the UI for your table
  return (
    <>
      <VirtualTableStyles>
        <Container className="table-wrap" data-testid={testId}>
          <Container {...getTableProps()} className="table">
            <Container width={isReport ? 'fit-content' : undefined}>
              {headerGroups.map((headerGroup) => (
                <Container
                  {...headerGroup.getHeaderGroupProps()}
                  className="thead"
                  width={isReport ? `calc(100%) !important` : totalColumnsWidth}
                  borderBottom={
                    enableSeparator
                      ? {
                          width: '1px',
                          color: 'var(--border-color-default)',
                          style: 'solid',
                        }
                      : undefined
                  }
                >
                  {headerGroup.headers.map((column) => (
                    <Container
                      {...column.getHeaderProps()}
                      className="th"
                      padding={
                        isReport
                          ? '0 var(--gutter-2x) !important'
                          : '0 !important'
                      }
                      width={column.width || undefined}
                    >
                      {column.render('Header')}
                    </Container>
                  ))}
                </Container>
              ))}
            </Container>

            {placeholderKey && rows.length === 0 ? (
              <Container
                display="flex"
                justifyContent="center"
                padding="var(--gutter-3x)"
              >
                <I18NText
                  id={placeholderKey}
                  color="var(--font-color-placeholder)"
                />
              </Container>
            ) : null}

            <Container {...getTableBodyProps()}>
              <FixedSizeList
                height={
                  size.height ? size.height - TABLE_ROW_HEIGHT : TABLE_HEIGHT
                }
                itemCount={rows.length}
                itemSize={TABLE_ROW_HEIGHT}
                width={isReport ? `${totalWidth}px` : totalColumnsWidth}
              >
                {RenderRow}
              </FixedSizeList>
            </Container>
          </Container>
        </Container>
      </VirtualTableStyles>
    </>
  )
}

export const StyledVirtualTable = withSize({
  monitorHeight: true,
})(ReactVirtualTable)
