import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  StyledButton,
  Text,
  StyledSelect2,
  StyledInput2,
  TooltipWrapper,
  StyledPrefix,
} from '../../Global'
import I18NText from '../../I18NText'
import {
  EReportColumnHeader,
  EReportFilterOperations,
} from '../../../models/core'
import { sanitizeDataTestId } from '../../../utils'
import {
  TDatasetWhitelistKey,
  TGlobalMetrics,
} from '../../ReportPageWrapperV3/models'
import {
  selectReportingFilters,
  setFilterBy,
  setFilterOperation,
  setFilterValue,
} from '../../../store/reportingFilters'
import { AppContext, EOrgType } from '../../../models'
import { setRefreshTable } from '../../../store/refreshTable'
import { setRefreshChart } from '../../../store/refreshChart'
import useGetCurrentCurrency from '../../../hooks/useGetCurrentCurrency'
import { selectReportingData } from '../../../store/reportingData'
import { useGetDatasetKeyChartWhitelist } from '../../ReportPageWrapperV3/hooks/use-get-dataset-key-chart-whitelist'

export interface IMetricFilterForm {
  filterBy?: EReportColumnHeader
  filterOperation?: EReportFilterOperations
  filterValue?: number
}

export const MetricFilter = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [appContext] = useContext(AppContext)
  const datasetKey = useSelector(selectReportingData)
  const metricsData =
    useGetDatasetKeyChartWhitelist({
      key: datasetKey as TDatasetWhitelistKey,
    }) || []
  const {
    filterBy: reportingFilterBy,
    filterOperation: reportingFilterOperation,
    filterValue: reportingFilterValue,
  } = useSelector(selectReportingFilters)
  const { symbol: currencySymbol } = useGetCurrentCurrency()
  const isMetricByPercentageOnInit = metricsData.find(
    (metric) => metric.metricName === reportingFilterBy
  )?.isPercentage
  const [metricFilterModal, setMetricFilterModal] = useState<IMetricFilterForm>(
    {
      filterBy: reportingFilterBy,
      filterOperation: reportingFilterOperation,
      filterValue:
        isMetricByPercentageOnInit &&
        reportingFilterValue &&
        reportingFilterValue > 0
          ? reportingFilterValue * 100
          : reportingFilterValue,
    }
  )
  const [inputPrefix, setInputPrefix] = useState<Array<ReactNode> | ReactNode>(
    <></>
  )
  const metrics: Array<TGlobalMetrics> =
    metricsData.map((data) => data.metricName) || []

  const methods = useForm<IMetricFilterForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
    trigger,
  } = methods

  const onSubmit = async (data: IMetricFilterForm) => {
    const isMetricByPercentageOnSubmit = metricsData.find(
      (metric) => metric.metricName === data.filterBy
    )?.isPercentage

    const filteredValue =
      isMetricByPercentageOnSubmit && data.filterValue && data.filterValue > 0
        ? data.filterValue! / 100
        : data.filterValue

    dispatch(setFilterBy({ filterBy: data.filterBy }))
    dispatch(setFilterOperation({ filterOperation: data.filterOperation }))
    dispatch(
      setFilterValue({
        filterValue: filteredValue,
      })
    )
    dispatch(setRefreshTable({ refreshTable: true }))
    dispatch(setRefreshChart({ refreshChart: true }))
    reset()
  }

  useEffect(() => {
    if (
      metricsData.find((data) => data.metricName === metricFilterModal.filterBy)
        ?.isCurrency
    ) {
      setInputPrefix(
        <StyledPrefix marginTop="6px">{currencySymbol}</StyledPrefix>
      )
    } else if (
      metricsData.find((data) => data.metricName === metricFilterModal.filterBy)
        ?.isPercentage
    ) {
      setInputPrefix(<StyledPrefix marginTop="6px">%</StyledPrefix>)
    } else {
      setInputPrefix(<></>)
    }
    if ((metricFilterModal.filterValue || 0) > 0) {
      trigger('filterValue')
    }
  }, [metricFilterModal.filterBy])

  return (
    <Container width="240px">
      <Container paddingLeft="var(--gutter-1x)" flex="1">
        <Text fontWeight={400}>
          <I18NText id="reporting.filter.select.metric" preset="h6" />
        </Text>
      </Container>
      <Container paddingTop="var(--gutter-2x)" paddingBottom="var(--gutter-1x)">
        <Controller
          name="filterBy"
          control={control}
          defaultValue={metricFilterModal.filterBy}
          render={({ field }) => (
            <StyledSelect2
              id="filterBy"
              {...field}
              value={metricFilterModal.filterBy}
              padding="var(--gutter-1x)"
              error={errors.filterBy}
              options={metrics.map((metric, index) => {
                return {
                  id: index,
                  label: intl.formatMessage({
                    id: `report.table.header.${
                      appContext.userOrg?.type.toLowerCase() ||
                      EOrgType.Retailer.toLowerCase()
                    }.${metric.toLowerCase()}`,
                  }),
                  value: metric,
                  'data-testid': sanitizeDataTestId(`metric-option-${metric}`),
                }
              })}
              onChange={(value) => {
                setMetricFilterModal({
                  ...metricFilterModal,
                  filterBy: value as EReportColumnHeader,
                })
                field.onChange(value)
              }}
              label={intl.formatMessage({
                id: 'reporting.filter.select.metric.placeholder',
              })}
              data-testid="reporting-filter-by-metric-select"
            />
          )}
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: 'error.required',
              }),
            },
          }}
        />
      </Container>
      <Container paddingLeft="var(--gutter-1x)" flex="1">
        <Text fontWeight={400}>
          <I18NText id="reporting.filter.select.operation" preset="h6" />
        </Text>
      </Container>
      <Container paddingTop="var(--gutter-2x)" paddingBottom="var(--gutter-1x)">
        <Controller
          name="filterOperation"
          control={control}
          defaultValue={metricFilterModal.filterOperation}
          render={({ field }) => (
            <StyledSelect2
              id="filterOperation"
              {...field}
              value={metricFilterModal.filterOperation}
              padding="var(--gutter-1x)"
              error={errors.filterOperation}
              options={Object.values(EReportFilterOperations).map(
                (operation, index) => {
                  return {
                    id: index,
                    label: intl.formatMessage({
                      id: `reporting.filter.operation.${operation.toLowerCase()}`,
                    }),
                    value: operation,
                    'data-testid': sanitizeDataTestId(
                      `operation-option-${operation}`
                    ),
                  }
                }
              )}
              label={intl.formatMessage({
                id: 'reporting.filter.select.operation.placeholder',
              })}
              onChange={(value) => {
                setMetricFilterModal({
                  ...metricFilterModal,
                  filterOperation: value as EReportFilterOperations,
                })
                field.onChange(value)
              }}
              data-testid="reporting-filter-operation-select"
            />
          )}
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: 'error.required',
              }),
            },
          }}
        />
      </Container>
      <Container paddingLeft="var(--gutter-1x)" flex="1">
        <Text fontWeight={400}>
          <I18NText id="reporting.filter.insert.value" preset="h6" />
        </Text>
      </Container>
      <Container paddingTop="var(--gutter-2x)" paddingBottom="var(--gutter-2x)">
        <Controller
          name="filterValue"
          control={control}
          defaultValue={metricFilterModal.filterValue}
          render={({ field }) => {
            return (
              <TooltipWrapper
                showTooltip={metricFilterModal.filterBy === undefined}
                tooltipKey="reporting.filter.insert.value.tooltip"
              >
                <StyledInput2
                  key={metricFilterModal.filterBy}
                  id="filterValue"
                  data-testid="reporting-filter-value-input"
                  value={metricFilterModal.filterValue}
                  field={field}
                  disabled={metricFilterModal.filterBy === undefined}
                  onChange={(e) => {
                    setMetricFilterModal({
                      ...metricFilterModal,
                      filterValue: parseFloat(e.target.value),
                    })
                    field.onChange(e)
                  }}
                  label={intl.formatMessage({
                    id: 'reporting.filter.insert.value.placeholder',
                  })}
                  error={errors.filterValue}
                  type="number"
                  inputPadding={{
                    paddingLeft: 'var(--gutter-default)',
                    paddingRight: 'var(--gutter-default)',
                  }}
                  prefix={inputPrefix}
                />
              </TooltipWrapper>
            )
          }}
          rules={{
            min: {
              value: 0,
              message: intl.formatMessage({
                id: 'retailer.errors.min.zero',
              }),
            },
            validate: (value) => {
              if (
                metricsData.find(
                  (metric) => metric.metricName === metricFilterModal.filterBy
                )?.isPercentage &&
                (value || 0) > 100
              ) {
                return intl.formatMessage({
                  id: 'retailer.errors.max.100',
                })
              }
            },
            required: {
              value: true,
              message: intl.formatMessage({
                id: 'error.required',
              }),
            },
          }}
        />
      </Container>
      <Container display="flex" justifyContent="right">
        <StyledButton
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
          width="100px"
          height="40px"
        >
          <Container
            display="grid"
            height="calc(40px - var(--gutter-2x))"
            padding="var(--gutter-mini)"
          >
            <I18NText
              id="reporting.filter.apply"
              preset="cta-sm"
              fontSize="var(--font-size-ps)"
              data-testid="reporting-filter-metric-apply-button"
            />
          </Container>
        </StyledButton>
      </Container>
    </Container>
  )
}
