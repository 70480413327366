import React, { useMemo, useState } from 'react'
import { Column } from 'react-table'
import { AxiosError } from 'axios'
import { ICategoryAttribution } from '../../../models/core'
import { IError, TTranslationKey, TVirtualTableData } from '../../../models'
import { useGetRetailerAndAdvertiserIds } from '../../../components/ReportPageWrapperV3/hooks/use-get-retailer-and-advertiser-ids'
import useGetRetailerCategoriesAttribution from '../../../hooks/use-get-retailer-categories-attribution-v3'
import useGetRetailerCategories from '../../../hooks/use-get-retailer-categories-v3'
import {
  TooltipWrapper,
  Container,
  EllipsisContainer,
  StyledVirtualTable,
  CheckBox,
  FilterButton,
} from '../../../components/Global'
import { sanitizeDataTestId } from '../../../utils'
import I18NText from '../../../components/I18NText'
import {
  POST_CLICK_ADD_TO_CART,
  POST_CLICK_PURCHASES,
  POST_VIEW_ADD_TO_CART,
  POST_VIEW_PURCHASES,
} from '../../../utils/constants'
import useShowToast from '../../../hooks/useShowToast'
import { ErrorToast } from '../../../components/ToastCard/ErrorToast'
import { CategoryAttributionPopover } from '../components/CategoryAttributionPopover'
import { CategoryAttributionSearch } from '../components/CategoryAttributionSearch'
import { CategoryAttributionBulkUpdateModal } from '../components/CategoryAttributionBulkUpdateModal'

export const CategoryAttribution = () => {
  const { retailerId } = useGetRetailerAndAdvertiserIds()
  const { data: categoriesResponseData } = useGetRetailerCategories({
    retailerId,
  })
  const {
    data: categoriesAttributionResponseData,
    refetch: refetchGetCategoriesAttribution,
  } = useGetRetailerCategoriesAttribution({
    retailerId,
  })

  const [
    showUpdateCategoriesAttributionBulk,
    setShowUpdateCategoriesAttributionBulk,
  ] = useShowToast(false)
  const [showUpdateErrorToast, setShowUpdateErrorToast] = useShowToast(false)
  const [errorUpdate, setErrorUpdate] = useState<TTranslationKey>()
  const [searchQuery, setSearchQuery] = useState('')
  const [isAllSelected, setIsAllSelected] = useState(false)
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([])

  const parentCategories =
    categoriesResponseData?.data.categories?.filter((cat) => !cat.parent) || []

  const filteredParentCategories = useMemo(() => {
    return parentCategories.filter((cat) =>
      cat.title.toLowerCase().includes(searchQuery.toLowerCase())
    )
  }, [searchQuery, parentCategories])

  const columns = useMemo<
    Array<
      Column<TVirtualTableData<ICategoryAttribution & { selectedId: number }>>
    >
  >(() => {
    const columnList: Array<
      Column<TVirtualTableData<ICategoryAttribution & { selectedId: number }>>
    > = [
      {
        Header: (
          <Container paddingLeft="var(--gutter-1x)">
            <CheckBox
              type="checkbox"
              onChange={(event: React.ChangeEvent<{ checked: unknown }>) => {
                if (event.target.checked) {
                  setSelectedCategoryIds(
                    filteredParentCategories.map((r) => r.id)
                  )
                  setIsAllSelected(Boolean(event.target.checked))
                } else {
                  setSelectedCategoryIds([])
                  setIsAllSelected(Boolean(event.target.checked))
                }
              }}
              checked={
                filteredParentCategories.length
                  ? filteredParentCategories
                      .map((r) => r.id)
                      .every((id) => {
                        return selectedCategoryIds.includes(id)
                      })
                  : false
              }
              dataTestId="select-all-categories-attribution"
            />
          </Container>
        ),
        accessor: 'selectedId',
        width: '72px',
      },
      {
        Header: <I18NText id="retailer.settings.category.name" />,
        accessor: 'categoryId',
      },
      {
        Header: (
          <I18NText id="retailer.settings.category.post.click.purchases" />
        ),
        accessor: 'postClickPurchases',
      },

      {
        Header: (
          <I18NText id="retailer.settings.category.post.view.purchases" />
        ),
        accessor: 'postViewPurchases',
      },
    ]
    return columnList
  }, [isAllSelected, filteredParentCategories])

  const categoriesAttribution =
    categoriesAttributionResponseData?.data.categoriesAttribution || []

  const onCategoryUpdate = () => {
    refetchGetCategoriesAttribution()
  }
  const onCategoryUpdateError = (error: AxiosError<IError, any>) => {
    setShowUpdateErrorToast(true)
    setErrorUpdate(error?.response?.data.errorKey as unknown as TTranslationKey)
  }

  const data: Array<TVirtualTableData<ICategoryAttribution>> =
    filteredParentCategories.map((category, index) => {
      const categoryAttribution = categoriesAttribution.find(
        (catAttribution) => catAttribution?.categoryId === category.id
      ) || {
        categoryId: category.id,
        postClickAddToCart: POST_CLICK_ADD_TO_CART, // not used atm
        postViewAddToCart: POST_VIEW_ADD_TO_CART, // not used atm
        postClickPurchases: POST_CLICK_PURCHASES,
        postViewPurchases: POST_VIEW_PURCHASES,
      }

      const row: TVirtualTableData<
        ICategoryAttribution & { selectedId: number }
      > = {
        selectedId: (
          <Container paddingLeft="var(--gutter-1x)" display="flex">
            <CheckBox
              type="checkbox"
              onChange={(event: React.ChangeEvent<{ checked: unknown }>) => {
                if (event.target.checked) {
                  setSelectedCategoryIds([...selectedCategoryIds, category.id])
                } else {
                  setSelectedCategoryIds(
                    selectedCategoryIds.filter((id) => id !== category.id)
                  )
                }
              }}
              checked={selectedCategoryIds.includes(category.id)}
              dataTestId={`select-${category.id}}-categories-attribution`}
            />
          </Container>
        ),
        categoryId: (
          <Container
            flex="1"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            data-testid={`category-attribution-table-container-category-name-${index}`}
          >
            <TooltipWrapper
              showTooltip={true}
              tooltip={category.title}
              dataTestId={sanitizeDataTestId(
                `category-attribution-table-${category.title}-tooltip`
              )}
              key={category.title}
            >
              <EllipsisContainer paddingRight="var(--gutter-1x)">
                {category.title}
              </EllipsisContainer>
            </TooltipWrapper>
          </Container>
        ),
        postClickPurchases: (
          <Container
            flex="1"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            data-testid={`category-attribution-table-container-category-post-click-purchases-${index}`}
          >
            {categoryAttribution.postClickPurchases} <I18NText id="days" />
            <CategoryAttributionPopover
              categoryAttribution={categoryAttribution}
              onCategoryUpdate={onCategoryUpdate}
              onCategoryUpdateError={onCategoryUpdateError}
              propertyToUpdate="postClickPurchases"
            />
          </Container>
        ),
        postViewPurchases: (
          <Container
            flex="1"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            data-testid={`category-attribution-table-container-category-post-view-purchases-${index}`}
          >
            {categoryAttribution.postViewPurchases} <I18NText id="days" />
            <CategoryAttributionPopover
              categoryAttribution={categoryAttribution}
              onCategoryUpdate={onCategoryUpdate}
              onCategoryUpdateError={onCategoryUpdateError}
              propertyToUpdate="postViewPurchases"
            />
          </Container>
        ),
      }
      return row
    })

  return (
    <>
      <Container padding="var(--gutter-5x) var(--gutter-4x)" overflow="auto">
        <Container flex="1" paddingBottom="var(--gutter-2x)">
          <Container display="flex" alignItems="center">
            <I18NText
              id="settings.category.attribution.title"
              preset="h3"
              data-testid="settings-category-attribution-title"
            />
          </Container>
          <Container display="flex" marginTop="var(--gutter-1x)">
            {selectedCategoryIds.length > 0 ? (
              <Container marginRight="auto">
                <FilterButton
                  lh="0"
                  fontSize="var(--font-size-ps)"
                  width="fit-content"
                  height="40px"
                  data-testid="category-attribution-bulk-button"
                  onClick={() => {
                    setShowUpdateCategoriesAttributionBulk(true)
                  }}
                >
                  <I18NText id="settings.category.attribution.bulk.button" />
                </FilterButton>
              </Container>
            ) : null}
            <Container marginLeft="auto">
              <CategoryAttributionSearch
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </Container>
          </Container>
        </Container>
        <StyledVirtualTable columns={columns} data={data} />
      </Container>
      <CategoryAttributionBulkUpdateModal
        showCategoryAttributionModal={showUpdateCategoriesAttributionBulk}
        setShowCategoryAttributionModal={(e: boolean) =>
          setShowUpdateCategoriesAttributionBulk(e)
        }
        onUpdate={() => {
          refetchGetCategoriesAttribution()
        }}
        onError={onCategoryUpdateError}
        categoryAttributionIdsToUpdate={selectedCategoryIds}
      />
      <ErrorToast
        show={showUpdateErrorToast}
        onClose={() => {
          setShowUpdateErrorToast(false)
          setErrorUpdate(undefined)
        }}
        translationKey={errorUpdate}
      />
    </>
  )
}
