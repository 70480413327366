import { useMutation } from 'react-query'
import { IError } from '@unfoldrtech/portal-mic'
import { AxiosError, AxiosResponse } from 'axios'
import { generateCorrelationId } from '../utils/helpers/generateCorrelationId'
import { ICategoryAttribution } from '../models/core'
import { coreAPIV3 } from '../services/coreAPIV3'

export default function usePutCategoryAttributionBatch(params: {
  retailerId: number
}) {
  return useMutation<
    AxiosResponse<void>,
    AxiosError<IError>,
    { payload: Array<ICategoryAttribution> }
  >([`usePutCategoryAttributionBatch_${params.retailerId}`], ({ payload }) => {
    return coreAPIV3
      .retailerInventory()
      .putCategoryAttributionBatch(
        params.retailerId,
        generateCorrelationId(),
        payload
      )
  })
}
