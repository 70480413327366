import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import {
  Container,
  StyledInput,
  StyledSuffix,
  SVGContainer,
  IconZoom,
} from '../../../components/Global'

export const CategoryAttributionSearch = ({
  searchQuery,
  setSearchQuery,
}: {
  searchQuery: string
  setSearchQuery: (e: string) => void
}) => {
  const intl = useIntl()
  const [showSearchInput, setShowSearchInput] = useState(false)
  return (
    <>
      {showSearchInput ? (
        <StyledInput
          id="search-products"
          placeholder={intl.formatMessage({
            id: 'settings.category.attribution.search',
          })}
          value={searchQuery}
          onChange={(e: React.ChangeEvent<{ value: string }>) =>
            setSearchQuery(e.target.value)
          }
          type="text"
          data-testid="campaign-search-products"
          suffix={
            <StyledSuffix
              onClick={() => {
                if (!searchQuery) setShowSearchInput(false)
              }}
            >
              <SVGContainer
                cursor={!searchQuery ? 'pointer' : 'default'}
                lineHeight={1}
              >
                <IconZoom />
              </SVGContainer>
            </StyledSuffix>
          }
          inputPadding={{
            paddingLeft: 'var(--gutter-4x)',
            paddingRight: 'var(--gutter-1x)',
          }}
          containerSize={{
            height: 'var(--font-size-h1)',
            width: '310px',
          }}
          containerBorder={{
            border: {
              width: '1px',
              style: 'solid',
              color: 'var(--border-color-default)',
              radius: 'var(--gutter-8x)',
            },
          }}
        />
      ) : (
        <Container
          width="var(--font-size-h1)"
          height="var(--font-size-h1)"
          display="flex"
          justifyContent="center"
          alignItems="center"
          border={{
            width: '1px',
            style: 'solid',
            color: 'var(--border-color-default)',
            radius: '50%',
          }}
          onClick={() => setShowSearchInput(true)}
          cursor="pointer"
          data-testid="campaign-search-products-trigger"
        >
          <SVGContainer fontSize="var(--font-size-sm)">
            <IconZoom />
          </SVGContainer>
        </Container>
      )}
    </>
  )
}
