import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError } from '@unfoldrtech/portal-mic'
import { generateCorrelationId, getTimezone } from '../utils/helpers'
import { TDAPositionsReportChartFilters, TPPRetailerId } from '../models/types'
import {
  EReportingTimeWindow,
  IDAReportingPositionsChartResponse,
} from '../models'
import { coreAPIV3 } from '../services/coreAPIV3'
import { ETimeBucketType } from '../models/core'

export default function useGetRetailerReportingDAPositionsChartV3({
  retailerId,
  advertiserId,
  campaignId,
  adGroupId,
  timeWindow,
  startDate,
  endDate,
  tzZone,
  aggregationType,
  filterBy,
  filterOperation,
  filterValue,
  name,
  status,
  enabled = true,
  suspense = true,
}: {
  enabled?: boolean
  suspense?: boolean
} & TDAPositionsReportChartFilters &
  TPPRetailerId) {
  const queryKeyName = `getRetailerReportingDAPositionsChart_${retailerId}_${advertiserId}`

  return useQuery<
    AxiosResponse<IDAReportingPositionsChartResponse>,
    AxiosError<IError>
  >(
    [queryKeyName, { start: startDate, end: endDate }],
    () => {
      return coreAPIV3
        .retailerReporting()
        .getRetailerPositionsReportChart(
          retailerId,
          generateCorrelationId(),
          advertiserId,
          campaignId,
          adGroupId,
          timeWindow as unknown as EReportingTimeWindow,
          startDate,
          endDate,
          tzZone || getTimezone(),
          aggregationType as ETimeBucketType,
          filterBy,
          filterOperation,
          filterValue,
          name,
          status
        )
    },
    { suspense, enabled }
  )
}
