import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Container, IconCross, SVGContainer } from '../../../Global'
import StyledPill from '../../../Pills/StyledPill'
import {
  selectReportingFilters,
  setFilterBy,
  setFilterOperation,
  setFilterValue,
} from '../../../../store/reportingFilters'
import { setRefreshChart } from '../../../../store/refreshChart'
import { setRefreshTable } from '../../../../store/refreshTable'
import { EOrgType } from '../../../../models/core'
import { AppContext } from '../../../../models'

export const MetricPill = ({ onClick }: { onClick: () => void }) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const [appContext] = useContext(AppContext)

  const { filterBy: reportingFilterBy } = useSelector(selectReportingFilters)
  const title = intl.formatMessage({
    id: 'reporting.filter.filter.pill.metric',
  })
  const filterByTranslation = reportingFilterBy
    ? intl.formatMessage({
        id: `report.table.header.${
          appContext.userOrg?.type.toLowerCase() ||
          EOrgType.Retailer.toLowerCase()
        }.${reportingFilterBy.toLowerCase()}`,
      })
    : ''

  const onClearMetric = () => {
    dispatch(setFilterBy({ filterBy: undefined }))
    dispatch(setFilterOperation({ filterOperation: undefined }))
    dispatch(setFilterValue({ filterValue: undefined }))
    dispatch(setRefreshTable({ refreshTable: true }))
    dispatch(setRefreshChart({ refreshChart: true }))
  }

  return reportingFilterBy ? (
    <Container display="flex" columnGap="var(--gutter-1x)" alignItems="center">
      <Container position="relative" key="metric">
        <StyledPill
          isVisible
          pillId="metric"
          data={{
            title: `${title}: ${filterByTranslation}`,
          }}
          tooltipText={filterByTranslation}
          showTooltip={(filterByTranslation || '').length > 15}
          suffix={
            <SVGContainer fontSize="var(--font-size-xs)">
              <IconCross />
            </SVGContainer>
          }
          onClear={() => onClearMetric()}
          onClick={() => onClick()}
          badgeSize={{
            height: '35px',
            maxWidth: '175px',
          }}
          badgeBackground="#7d7d7d"
          dataTestId="reporting-filter-metric-pill"
        />
      </Container>
    </Container>
  ) : null
}
