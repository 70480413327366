import { PayloadAction } from '@reduxjs/toolkit'
import { TReportingFilters } from '@unfoldrtech/portal-mic/dist/src/models/types'
import { EReportFilterOperations } from '../../../models/core'

const setFilterOperation = (
  state: TReportingFilters,
  action: PayloadAction<{
    filterOperation?: EReportFilterOperations
  }>
) => {
  state.filterOperation = action.payload
    .filterOperation as TReportingFilters['filterOperation']
}

export default setFilterOperation
