import React, { useState, useEffect, useContext } from 'react'
import { EOrgType } from '@unfoldrtech/portal-mic'
import { schemeTableau10 } from 'd3-scale-chromatic'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Container, GridContainer, StyledImage } from '../../../Global'
import { LineChartV3 } from '../../../Widgets'
import { TChartMetricData } from '../../../../models/types'
import Loading from '../../../Loading'
import { sanitizeDataTestId } from '../../../../utils/sanitizeDataTestId'
import useGetCurrentCurrency from '../../../../hooks/useGetCurrentCurrency'
import { MetricsSelectorV3 } from '../../components/MetricSelectorV3'
import { useGetRetailerAndAdvertiserIds } from '../../hooks/use-get-retailer-and-advertiser-ids'
import { getDefaultMetric } from '../../helpers/get-default-metric'
import { AdGroupContext, AppContext, CampaignContext } from '../../../../models'
import { MetricWidget } from '../../components/MetricWidget'
import { useGetChartXYPropsV3 } from '../../hooks/use-get-chart-x-y-props-v3'
import { TDACategoriesMetrics, TSPCategoriesMetrics } from '../models'
import { getFormatOptionCurrency } from '../../helpers/get-format-option-currency'
import { getFormatOptionNumber } from '../../helpers/get-format-option-number'
import { useGetDatasetKeyChartWhitelist } from '../../hooks/use-get-dataset-key-chart-whitelist'
import { TDatasetWhitelistKey, TGlobalMetrics } from '../../models'
import { getFormatOptionPercent } from '../../helpers'
import {
  EChannelTypeGlobal,
  EReportingMetricNumberType,
  IDAAggregates,
  IDAAggregatesTypes,
  IDAReportCategoriesChartData,
  IDOOHAggregates,
  IDOOHAggregatesTypes,
  IGenericAggregates,
  IGenericAggregatesTypes,
  ISPAggregates,
  ISPAggregatesTypes,
  ISPReportCategoriesChartData,
} from '../../../../models/core'
import { getTableAndChartCategoriesFn } from '../helpers/get-table-and-chart-categories-fn'
import { getCategoriesEDatasetKeyFromChannelTypeGlobal } from '../helpers/get-categories-e-dataset-key-from-channel-type-global'
import { selectCategoriesChartReportingFilters } from '../../../../store/reportingFilters'
import {
  selectRefreshChart,
  setRefreshChart,
} from '../../../../store/refreshChart'
import { setReportingData } from '../../../../store/reportingData'

const lineColors = schemeTableau10
const bigNumberWidgetWidth = '214px'

type TCategoriesMetrics = TSPCategoriesMetrics | TDACategoriesMetrics
type TReportCategoriesChartData =
  | ISPReportCategoriesChartData
  | IDAReportCategoriesChartData

export const ReportingCategoriesChartWrapper = ({
  channelType,
}: {
  channelType: EChannelTypeGlobal
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const adGroup = useContext(AdGroupContext)

  const orgType = appContext.userOrg!.type
  const campaignId = campaign?.id
  const adGroupId = adGroup?.id

  const { symbol: currencySymbol, text: currencyText } = useGetCurrentCurrency()
  const { retailerId, advertiserId } = useGetRetailerAndAdvertiserIds()

  const hookFn = getTableAndChartCategoriesFn({
    orgType,
  })
  const getChartDataFn = hookFn.chartFn!

  const [selectedMetricNames, setSelectedMetricNames] = useState<
    Array<TCategoriesMetrics>
  >([])
  const [pinnedMetricNames, setPinnedMetricNames] = useState<
    Array<TCategoriesMetrics>
  >([])

  const [chartData, setChartData] = useState<Array<TChartMetricData>>([])
  const [chartParamsUpdated, setChartParamsUpdated] = useState<boolean>()

  const {
    startDate,
    endDate,
    timeWindow,
    aggregationType,
    tzZone,
    filterBy,
    filterOperation,
    filterValue,
    name,
    status,
  } = useSelector(selectCategoriesChartReportingFilters)
  const { xAxisProps, yAxisProps } = useGetChartXYPropsV3(chartData)
  const refreshChart = useSelector(selectRefreshChart)

  const {
    data: chartDataResponseObj,
    refetch,
    isFetching,
    isFetchedAfterMount,
    dataUpdatedAt,
  } = getChartDataFn({
    advertiserId,
    retailerId,
    channelType,
    campaignId,
    adGroupId,
    timeWindow,
    startDate,
    endDate,
    tzZone,
    aggregationType,
    filterBy,
    filterOperation,
    filterValue,
    name: name ? encodeURI(name) : undefined,
    status,
    skip: !!channelType,
    enabled: false,
  })

  const datasetKey =
    (chartDataResponseObj?.data.chart?.datasetKey as TDatasetWhitelistKey) ||
    getCategoriesEDatasetKeyFromChannelTypeGlobal(channelType)

  dispatch(
    setReportingData({
      datasetKey,
    })
  )

  const chartDataResponse: Array<TReportCategoriesChartData> =
    chartDataResponseObj?.data?.chart?.data ?? []

  const whitelistDataList = useGetDatasetKeyChartWhitelist({
    key: datasetKey,
  })

  const responseMetrics = Array.from(
    new Set(chartDataResponse.map((row) => Object.keys(row)).flat())
  )

  const whitelistData = whitelistDataList
    .map((wData) => {
      const whitelistItem = responseMetrics.find(
        (metric) => metric === wData.metricName
      )

      if (whitelistItem) {
        return wData
      }
      return undefined
    })
    .filter((wData) => wData !== undefined)

  const dataMetricWhitelisted = whitelistData.map((data) => {
    return data.metricName
  })

  const chartDataMetrics = chartDataResponse.length
    ? (dataMetricWhitelisted as TCategoriesMetrics[])
    : []

  const chartDataAggregates =
    chartDataResponseObj?.data?.chart?.summary.aggregates ?? {}

  const defaultMetric = getDefaultMetric({
    orgType,
    channelType,
    hasRevenue:
      !!chartDataAggregates.revenue && chartDataAggregates.revenue > 0,
    allowedMetricList: dataMetricWhitelisted,
  })

  const chartDataTypes =
    (chartDataResponseObj &&
      chartDataResponseObj?.data?.chart?.summary.types) ??
    {}

  const onToggleSelectedMetric = (metricName: TGlobalMetrics) => {
    let newSelectedMetrics: Array<TCategoriesMetrics> = JSON.parse(
      JSON.stringify(selectedMetricNames)
    )

    if (selectedMetricNames.includes(metricName as TCategoriesMetrics)) {
      newSelectedMetrics = newSelectedMetrics.filter(
        (selectedMetric) => selectedMetric !== metricName
      )
    } else {
      if (selectedMetricNames.length === 2) {
        newSelectedMetrics.shift()
      }
      newSelectedMetrics.push(metricName as TCategoriesMetrics)
    }
    setSelectedMetricNames(newSelectedMetrics)
  }

  const onUnpinMetric = (metricName: TGlobalMetrics) => {
    const newPinnedMetrics = pinnedMetricNames.filter(
      (pinnedMetric) => pinnedMetric !== metricName
    )

    setPinnedMetricNames(newPinnedMetrics)

    if (selectedMetricNames.includes(metricName as TCategoriesMetrics)) {
      onToggleSelectedMetric(metricName)
    }
  }

  const onPinMetric = (metricName: TGlobalMetrics) => {
    const metric = chartDataMetrics.find((dMetrics) => dMetrics === metricName)
    if (metric && !pinnedMetricNames.includes(metric)) {
      setPinnedMetricNames([...pinnedMetricNames, metric])
      onToggleSelectedMetric(metricName)
    }
  }

  const transformChartData = () => {
    if (selectedMetricNames.length) {
      const transformedChartData: Array<TChartMetricData> = []
      chartDataResponse.forEach((chartDataPoint) => {
        const chartDataObj: TChartMetricData = {
          date: chartDataPoint.time_bucket,
        }

        selectedMetricNames.forEach((metricName) => {
          const whitelistedMetric = whitelistData.find((wMetric) => {
            return wMetric.metricName === metricName
          })
          const metricValue =
            chartDataPoint[metricName as keyof TReportCategoriesChartData]

          let sanitisedValue =
            metricValue &&
            !Number.isNaN(Number(metricValue)) &&
            Number(metricValue) > 0
              ? Number(metricValue)
              : 0

          if (whitelistedMetric?.isPercentage === true) {
            sanitisedValue *= 100
          }

          // Round to max 2 decimals
          const formattedValue = Math.round(Number(sanitisedValue) * 100) / 100

          chartDataObj[whitelistedMetric?.translatedMetricName!] =
            formattedValue
        })
        transformedChartData.push(chartDataObj)
      })

      return transformedChartData
    }
    return []
  }

  const getFormattedMetricValue = (
    value: string | number,
    metric: TCategoriesMetrics
  ) => {
    let formattedValue: string | number = value
    const whitelisteMetric = whitelistData.find((wMetric) => {
      return wMetric.metricName === metric
    })

    if (whitelisteMetric?.isCurrency) {
      formattedValue = intl.formatNumber(
        Number(value),
        getFormatOptionCurrency(currencyText)
      )
    } else if (whitelisteMetric?.isPercentage) {
      formattedValue = intl.formatNumber(
        Number(value),
        getFormatOptionPercent()
      )
    } else {
      formattedValue = intl.formatNumber(Number(value), getFormatOptionNumber())
    }

    return `${formattedValue}`
  }

  useEffect(() => {
    if (refreshChart) {
      refetch()
      dispatch(setRefreshChart({ refreshChart: false }))
    }
  }, [refreshChart])

  useEffect(() => {
    if (startDate && endDate) {
      setChartParamsUpdated(true)
    }
  }, [startDate, endDate, aggregationType, channelType])

  useEffect(() => {
    if (chartParamsUpdated) {
      refetch()
      setChartParamsUpdated(false)
    }
  }, [chartParamsUpdated])

  useEffect(() => {
    if (isFetchedAfterMount && !pinnedMetricNames.length) {
      onPinMetric(defaultMetric)
    }
  }, [isFetchedAfterMount])

  // This use effect takes care of maintaining the same pinned metrics as the previous page when switching
  useEffect(() => {
    if (
      isFetchedAfterMount &&
      pinnedMetricNames.length &&
      chartDataResponse?.length
    ) {
      // Some of the previous selected pinned metrics might not be available on this page
      // We cannot pin them if they are not valid for this page
      const validPreExistingPinnedMetrics = pinnedMetricNames.filter(
        (metric) => {
          const metricIndex = chartDataMetrics.findIndex(
            (met) => met === metric
          )
          if (metricIndex > -1) {
            return chartDataMetrics.length > metricIndex
          }
          return false
        }
      )

      // In the case that we had a previous pinned metric but that was a metric not valid on this page
      // we need to set the default metric.
      if (pinnedMetricNames.length === 0) {
        if (chartDataMetrics.length) {
          pinnedMetricNames.push(defaultMetric)
        }
      }

      setPinnedMetricNames(validPreExistingPinnedMetrics)

      const sanitizedSelectedMetrics = selectedMetricNames.filter(
        (metricIndex) => validPreExistingPinnedMetrics.indexOf(metricIndex) > -1
      )
      if (sanitizedSelectedMetrics.length === 0) {
        sanitizedSelectedMetrics.push(pinnedMetricNames[0])
      }
      setSelectedMetricNames(sanitizedSelectedMetrics)
    }
  }, [isFetchedAfterMount])

  useEffect(() => {
    if (dataUpdatedAt || selectedMetricNames.length > 0) {
      setChartData(transformChartData())
    }
  }, [dataUpdatedAt, selectedMetricNames])

  return (
    <>
      {Boolean(chartDataMetrics.length) &&
      Boolean(chartDataAggregates) &&
      Boolean(chartDataTypes) ? (
        <>
          <GridContainer
            gridGap="var(--margin-default)"
            gridTemplateColumns={`repeat(4, minmax(${bigNumberWidgetWidth}, 1fr))`}
            padding="var(--margin-default) 0"
            width="100%"
            minHeight="116px"
          >
            {pinnedMetricNames.map((metric) => {
              const whitelistedMetric = whitelistData.find((wMetric) => {
                return wMetric.metricName === metric
              })

              const { value, metricAggregationType } = {
                value: (
                  chartDataAggregates as IGenericAggregates &
                    ISPAggregates &
                    IDAAggregates &
                    IDOOHAggregates & {
                      timestamp: EReportingMetricNumberType
                      time_bucket: EReportingMetricNumberType
                    }
                )[metric],
                metricAggregationType: (
                  chartDataTypes as IGenericAggregatesTypes &
                    ISPAggregatesTypes &
                    IDAAggregatesTypes &
                    IDOOHAggregatesTypes & {
                      timestamp: EReportingMetricNumberType
                      time_bucket: EReportingMetricNumberType
                    }
                )[metric],
              }

              const formattedValue: string = getFormattedMetricValue(
                value as number,
                whitelistedMetric?.metricName! as TCategoriesMetrics
              )

              const metricIndex = chartDataMetrics.findIndex(
                (met) => met === whitelistedMetric?.metricName
              )
              const borderBottomColor =
                lineColors[metricIndex % lineColors.length]

              return (
                <Container
                  data-testid={sanitizeDataTestId(
                    `metric-${whitelistedMetric?.metricName}`
                  )}
                  key={whitelistedMetric?.metricName}
                >
                  <MetricWidget
                    id={whitelistedMetric?.metricName!}
                    translatedMetricName={
                      whitelistedMetric?.translatedMetricName
                    }
                    metricName={whitelistedMetric?.metricName}
                    value={formattedValue}
                    description={metricAggregationType}
                    borderBottomColor={borderBottomColor}
                    onClick={onToggleSelectedMetric}
                    onRemove={onUnpinMetric}
                    isSelected={
                      !!whitelistedMetric?.metricName &&
                      selectedMetricNames.includes(
                        whitelistedMetric?.metricName as TCategoriesMetrics
                      )
                    }
                  />
                </Container>
              )
            })}
            {pinnedMetricNames.length < 4 && (
              <Container>
                <MetricsSelectorV3
                  whitelistedMetricData={whitelistData}
                  pinnedMetrics={pinnedMetricNames}
                  onChange={onPinMetric}
                />
              </Container>
            )}
          </GridContainer>

          <Container width="100%" height="300px">
            <LineChartV3
              chartData={chartData}
              metrics={selectedMetricNames.map((selectedMetric) => {
                const whitelisteMetric = whitelistData.find((wMetric) => {
                  return wMetric.metricName === selectedMetric
                })
                return whitelisteMetric!.translatedMetricName
              })}
              xAxisProps={xAxisProps}
              yAxiiProps={yAxisProps}
              metricColors={selectedMetricNames.map((metric) => {
                const metricIndex = chartDataMetrics.findIndex(
                  (met) => met === metric
                )
                return lineColors[metricIndex]
              })}
              metricUnits={selectedMetricNames.map((metric) => {
                const whitelisteMetric = whitelistData.find((wMetric) => {
                  return wMetric.metricName === metric
                })
                if (whitelisteMetric?.isCurrency) {
                  return `(${currencySymbol})`
                }
                if (whitelisteMetric?.isPercentage) {
                  return `(%)`
                }
                return ''
              })}
            />
          </Container>
        </>
      ) : (
        <StyledImage
          fluid
          data-testid="chart-placeholder"
          src={
            orgType === EOrgType.Retailer
              ? `${process.env.REACT_APP_CDN_URL}/images/f33ff4f9-cd01-49f5-ac76-77e8abed0473.png`
              : `${process.env.REACT_APP_CDN_URL}/images/13529de4-79e5-491d-83a7-1cf5b08b8d7b.png`
          }
          alt="home_placeholder"
          cursor="default"
        />
      )}
      <Loading show={isFetching} />
    </>
  )
}
