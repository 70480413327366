import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { AxiosError } from 'axios'
import {
  Container,
  StyledButton,
  StyledInput2,
  StyledModal,
} from '../../../components/Global'
import I18NText from '../../../components/I18NText'
import { ICategoryAttribution, IError } from '../../../models/core'
import {
  POST_CLICK_PURCHASES,
  POST_VIEW_PURCHASES,
} from '../../../utils/constants'
import usePutCategoryAttributionBatch from '../../../hooks/use-put-category-attribution-batch-v3'
import { useGetRetailerAndAdvertiserIds } from '../../../components/ReportPageWrapperV3/hooks/use-get-retailer-and-advertiser-ids'

export const CategoryAttributionBulkUpdateModal = ({
  showCategoryAttributionModal,
  setShowCategoryAttributionModal,
  categoryAttributionIdsToUpdate,
  onUpdate,
  onError,
}: {
  showCategoryAttributionModal: boolean
  setShowCategoryAttributionModal: (e: boolean) => void
  categoryAttributionIdsToUpdate: number[]
  onUpdate: () => void
  onError: (error: AxiosError<IError, any>) => void
}) => {
  const intl = useIntl()
  const { retailerId } = useGetRetailerAndAdvertiserIds()

  const {
    mutate: mutateCategoryAttributionBatch,
    isLoading: isUpdating,
    isSuccess,
    error,
    isError,
  } = usePutCategoryAttributionBatch({
    retailerId,
  })

  const onSubmit = (catAttribution: ICategoryAttribution) => {
    const payload = categoryAttributionIdsToUpdate.map((id) => ({
      ...catAttribution,
      categoryId: id,
    })) as ICategoryAttribution[]
    mutateCategoryAttributionBatch({
      payload,
    })
  }

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      postClickPurchases: POST_CLICK_PURCHASES,
      postViewPurchases: POST_VIEW_PURCHASES,
    } as ICategoryAttribution,
  })

  useEffect(() => {
    if (isSuccess) {
      onUpdate()
      setShowCategoryAttributionModal(false)
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      onError(error)
      setShowCategoryAttributionModal(false)
    }
  }, [isError])

  return (
    <StyledModal
      show={showCategoryAttributionModal}
      onHide={() => setShowCategoryAttributionModal(false)}
      dialogClassName="custom-category-attribution-bulk"
    >
      <StyledModal.Header closeButton>
        <StyledModal.Title>
          <Container
            display="flex"
            flexDirection="column"
            marginBottom="var(--gutter-3x)"
            preset="h3"
          >
            <I18NText
              id="settings.category.attribution.bulk.modal.title"
              data-testid="category-attribution-bulk-modal-title"
            />
          </Container>
        </StyledModal.Title>
      </StyledModal.Header>
      <StyledModal.Body>
        <>
          <I18NText id="postClickPurchases.days" preset="h6" fontWeight={500} />
          <Container
            width="100%"
            paddingTop="var(--gutter-mini)"
            marginBottom="var(--gutter-4x)"
          >
            <Controller
              name="postClickPurchases"
              control={control}
              render={({ field }) => (
                <StyledInput2
                  id="postClickPurchases"
                  field={field}
                  error={errors.postClickPurchases}
                  type="number"
                  inputPadding={{
                    paddingLeft: 'var(--gutter-1x)',
                  }}
                  onChange={(e) => {
                    field.onChange(parseInt(e.target.value, 10))
                  }}
                  containerSize={{
                    height: '40px',
                    width: '100%',
                  }}
                />
              )}
              rules={{
                validate: (value) => {
                  let errMsgId = ''

                  if (!value && value !== 0) {
                    errMsgId = 'error.required'
                  } else if (value < 1) {
                    errMsgId = 'retailer.errors.min.one'
                  }
                  return errMsgId.length
                    ? intl.formatMessage({
                        id: errMsgId,
                      })
                    : true
                },
              }}
            />
          </Container>
          <I18NText id="postViewPurchases.days" preset="h6" fontWeight={500} />
          <Container width="100%" paddingTop="var(--gutter-mini)">
            <Controller
              name="postViewPurchases"
              control={control}
              render={({ field }) => (
                <StyledInput2
                  id="postViewPurchases"
                  field={field}
                  error={errors.postViewPurchases}
                  type="number"
                  inputPadding={{
                    paddingLeft: 'var(--gutter-1x)',
                  }}
                  onChange={(e) => {
                    field.onChange(parseInt(e.target.value, 10))
                  }}
                  containerSize={{
                    height: '40px',
                    width: '100%',
                  }}
                />
              )}
              rules={{
                validate: (value) => {
                  let errMsgId = ''

                  if (!value && value !== 0) {
                    errMsgId = 'error.required'
                  } else if (value < 1) {
                    errMsgId = 'retailer.errors.min.one'
                  }
                  return errMsgId.length
                    ? intl.formatMessage({
                        id: errMsgId,
                      })
                    : true
                },
              }}
            />
          </Container>
        </>
      </StyledModal.Body>
      <StyledModal.Footer>
        <StyledButton
          width="100%"
          disabled={!isValid || isUpdating}
          onClick={handleSubmit(onSubmit)}
        >
          <I18NText id="apply" />
        </StyledButton>
      </StyledModal.Footer>
    </StyledModal>
  )
}
