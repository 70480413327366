import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { AxiosError } from 'axios'
import {
  StyledAntPopover,
  IconButton,
  IconEdit,
  Container,
} from '../../../components/Global'
import { ICategoryAttribution, IError } from '../../../models/core'
import { useGetRetailerAndAdvertiserIds } from '../../../components/ReportPageWrapperV3/hooks/use-get-retailer-and-advertiser-ids'
import usePutCategoryAttribution from '../../../hooks/use-put-category-attribution-v3'
import { CategoryAttributionPopoverContent } from './CategoryAttributionPopoverContent'

export const CategoryAttributionPopover = ({
  categoryAttribution,
  onCategoryUpdate,
  onCategoryUpdateError,
  propertyToUpdate,
}: {
  categoryAttribution: ICategoryAttribution
  onCategoryUpdate: () => void
  onCategoryUpdateError: (error: AxiosError<IError, any>) => void
  propertyToUpdate: 'postClickPurchases' | 'postViewPurchases'
}) => {
  const [open, setOpen] = useState(false)
  const { retailerId } = useGetRetailerAndAdvertiserIds()

  const { mutate: mutateCategoryAttribution, isLoading: isUpdating } =
    usePutCategoryAttribution({
      retailerId,
      categoryAttributionId: categoryAttribution.categoryId,
    })

  const handleOnCategorySubmit = (catAttribution: ICategoryAttribution) => {
    mutateCategoryAttribution(
      { payload: catAttribution },
      {
        onSuccess: () => {
          onCategoryUpdate()
        },
        onError: (error) => {
          onCategoryUpdateError(error)
        },
      }
    )
  }

  return (
    <Container display="inline" marginLeft="var(--gutter-1x)">
      <StyledAntPopover
        destroyTooltipOnHide
        open={open}
        onOpenChange={(visible) => setOpen(visible)}
        showArrow={false}
        trigger="click"
        content={
          <CategoryAttributionPopoverContent
            categoryAttribution={categoryAttribution}
            handleCategoryAttributionSubmit={handleOnCategorySubmit}
            isUpdating={isUpdating}
            propertyToUpdate={propertyToUpdate}
          />
        }
      >
        <IconButton
          opacity={1}
          onClick={() => setOpen(true)}
          disabled={isUpdating}
        >
          {isUpdating ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <IconEdit />
          )}
        </IconButton>
      </StyledAntPopover>
    </Container>
  )
}
