import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Container, IconCross, SVGContainer } from '../../../Global'
import StyledPill from '../../../Pills/StyledPill'
import {
  selectReportingFilters,
  setName,
} from '../../../../store/reportingFilters'
import { setRefreshChart } from '../../../../store/refreshChart'
import { setRefreshTable } from '../../../../store/refreshTable'

export const NamePill = ({ onClick }: { onClick: () => void }) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const { name: reportingName } = useSelector(selectReportingFilters)
  const title = intl.formatMessage({
    id: 'reporting.filter.filter.pill.name',
  })

  const onClearName = () => {
    dispatch(setName({ name: undefined }))
    dispatch(setRefreshTable({ refreshTable: true }))
    dispatch(setRefreshChart({ refreshChart: true }))
  }

  return reportingName ? (
    <Container display="flex" columnGap="var(--gutter-1x)" alignItems="center">
      <Container position="relative" key="name">
        <StyledPill
          isVisible
          pillId="name"
          data={{
            title: `${title}: ${reportingName}`,
          }}
          tooltipText={reportingName}
          showTooltip={(reportingName || '').length > 15}
          suffix={
            <SVGContainer fontSize="var(--font-size-xs)">
              <IconCross />
            </SVGContainer>
          }
          onClear={() => onClearName()}
          onClick={() => onClick()}
          badgeSize={{
            height: '35px',
            maxWidth: '175px',
          }}
          badgeBackground="#7d7d7d"
          dataTestId="reporting-filter-name-pill"
        />
      </Container>
    </Container>
  ) : null
}
