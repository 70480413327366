import { createSlice } from '@reduxjs/toolkit'
import reducers from './reducers'
import { IReportingData } from '../../models/interfaces/IReportingData'

const INITIAL_REPORTING_DATA_STATE: IReportingData = <IReportingData>{
  datasetKey: undefined,
}

export const reportingDataSlice = createSlice({
  name: 'reportingData',
  initialState: INITIAL_REPORTING_DATA_STATE,
  reducers: {
    resetReportingData: () => INITIAL_REPORTING_DATA_STATE,
    ...reducers,
  },
})
export const { setReportingData } = reportingDataSlice.actions

export const reportingDataReducer = reportingDataSlice.reducer
