import { PayloadAction } from '@reduxjs/toolkit'
import { IReportingData } from '../../../models/interfaces/IReportingData'

const setReportingData = (
  state: IReportingData,
  action: PayloadAction<IReportingData>
) => {
  state.datasetKey = action.payload.datasetKey
}

export default setReportingData
