import {
  EStatus,
  // IAdGroup,
  IAdGroupCreate,
  IAdGroupUpdate,
  EDealType,
} from '@unfoldrtech/portal-mic'
import { IAdGroup } from '../../../models/interfaces/ICampaignState'
import { getAdGroupTargetList } from './getAdGroupTargetList'
import { updateAdDataDestinationURL } from '../../../utils/helpers'
import { CTransformAdGroupUpdate } from '../../../models'

export function transformAdGroup(adGroup: IAdGroup): IAdGroupCreate {
  return {
    inventoryType: adGroup.inventoryType,
    targeting: getAdGroupTargetList(adGroup),
    title: adGroup.title,
    criteria: adGroup.criteria,
    days: adGroup.days,
    times: adGroup.times,
    productIdList: adGroup.productList?.map((p) => p.id) || [],
    creativesList: adGroup.creativesList?.map((creative) => ({
      ...creative,
      data: updateAdDataDestinationURL({
        destinationURLPrefix: creative.template.data.destinationURLPrefix,
        adData: creative.data,
      }),
    })),
  }
}

export function transformAdGroupUpdate(
  adGroup: IAdGroup,
  dealType: EDealType
): IAdGroupUpdate {
  const isProgrammatic = dealType === EDealType.Programmatic

  const productIdList =
    adGroup.productList
      ?.filter((product) => product.status !== EStatus.Disabled)
      .map((p) => p.id) || []

  return new CTransformAdGroupUpdate(
    productIdList,
    isProgrammatic ? getAdGroupTargetList(adGroup) : undefined,
    isProgrammatic ? adGroup.title : undefined,
    isProgrammatic ? adGroup.criteria : undefined,
    isProgrammatic ? adGroup.days : undefined,
    isProgrammatic ? adGroup.times : undefined
  )
}
