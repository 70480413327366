import React, { lazy } from 'react'

import { EUserRole } from '@unfoldrtech/portal-mic'
import { Route } from 'react-router-dom'
import { PortalRoutes } from './PortalRoutes'
import AuthenticatedRoute from './AuthenticatedRoute'
import { lazyRetry } from '../../utils/lazyRetry'
import { CategoryAttribution } from '../../pages/Settings/Routes/CategoryAttribution'

const UsersSigningKeys = lazy(() =>
  lazyRetry(() => import('../../pages/Settings/Routes/UsersSigningKeys'))
)

const Settings = lazy(() => lazyRetry(() => import('../../pages/Settings')))

const UserProfile = lazy(() =>
  lazyRetry(() => import('../../pages/Settings/Routes/UserProfile'))
)

const UserAndRoles = lazy(() =>
  lazyRetry(() => import('../../pages/Settings/Routes/UsersAndRoles'))
)

const UserPassword = lazy(() =>
  lazyRetry(() => import('../../pages/Settings/Routes/UserPassword'))
)

const OrgCurrency = lazy(() =>
  lazyRetry(() => import('../../pages/Settings/Routes/OrgCurrency'))
)

const OrgMfa = lazy(() =>
  lazyRetry(() => import('../../pages/Settings/Routes/OrgMfa'))
)

const AudienceFloorPriceModifiers = lazy(() =>
  lazyRetry(
    () => import('../../pages/Settings/Routes/AudienceFloorPriceModifiers')
  )
)

const RetailerSettingsRoutes = () => {
  return (
    <PortalRoutes>
      <Route
        path="/"
        element={
          <AuthenticatedRoute
            authRole={[
              EUserRole.AdvertiserInsights,
              EUserRole.RetailerInsights,
            ]}
          >
            <Settings />
          </AuthenticatedRoute>
        }
      >
        <Route
          path="profile"
          element={
            <AuthenticatedRoute
              authRole={[
                EUserRole.AdvertiserInsights,
                EUserRole.RetailerInsights,
              ]}
            >
              <UserProfile />
            </AuthenticatedRoute>
          }
          /* exact */
        />
        <Route
          path="password"
          element={
            <AuthenticatedRoute
              authRole={[
                EUserRole.AdvertiserInsights,
                EUserRole.RetailerInsights,
              ]}
            >
              <UserPassword />
            </AuthenticatedRoute>
          }
          /* exact */
        />
        <Route
          path="admin"
          element={
            <AuthenticatedRoute
              authRole={[EUserRole.RetailerAdmin, EUserRole.AdvertiserAdmin]}
            >
              <UserAndRoles />
            </AuthenticatedRoute>
          }
          /* exact */
        />
        <Route
          path="signing-keys"
          element={
            <AuthenticatedRoute authRole={[EUserRole.RetailerAdmin]}>
              <UsersSigningKeys />
            </AuthenticatedRoute>
          }
          /* exact */
        />
        <Route
          path="currency"
          element={
            <AuthenticatedRoute authRole={[EUserRole.RetailerAdmin]}>
              <OrgCurrency />
            </AuthenticatedRoute>
          }
          /* exact */
        />
        <Route
          path="mfa"
          element={
            <AuthenticatedRoute authRole={[EUserRole.RetailerAdmin]}>
              <OrgMfa />
            </AuthenticatedRoute>
          }
          /* exact */
        />

        <Route
          path="audience-modifiers"
          element={
            <AuthenticatedRoute authRole={[EUserRole.RetailerAdmin]}>
              <AudienceFloorPriceModifiers />
            </AuthenticatedRoute>
          }
          /* exact */
        />

        <Route
          path="category-attribution"
          element={
            <AuthenticatedRoute authRole={[EUserRole.RetailerInventory]}>
              <CategoryAttribution />
            </AuthenticatedRoute>
          }
          /* exact */
        />
      </Route>
    </PortalRoutes>
  )
}

export default RetailerSettingsRoutes
