import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { EDatasetKey } from '../models'
import { MetricFilter } from '../components/ReportingFilter/PopoverFilters/MetricFilter'
import { NameFilter } from '../components/ReportingFilter/PopoverFilters/NameFilter'
import { StatusFilter } from '../components/ReportingFilter/PopoverFilters/StatusFilter'
import { ECampaignStatus } from '../models/core'
import { ChannelFilter } from '../components/ReportingFilter/PopoverFilters/ChannelFilter'
import { selectReportingData } from '../store/reportingData'

export const useGetReportingFilterOptions = () => {
  const intl = useIntl()
  const datasetKey = useSelector(selectReportingData)
  let statuses: Array<ECampaignStatus> = [
    ECampaignStatus.Active,
    ECampaignStatus.Inactive,
    ECampaignStatus.Disabled,
  ]
  const isReportingCampaign = !!(
    datasetKey === EDatasetKey.CampaignGeneric ||
    datasetKey === EDatasetKey.CampaignSp ||
    datasetKey === EDatasetKey.CampaignDa ||
    datasetKey === EDatasetKey.CampaignDooh
  )
  const isReportingCreative = !!(
    datasetKey === EDatasetKey.CreativeDa ||
    datasetKey === EDatasetKey.CreativeDaAdgroup ||
    datasetKey === EDatasetKey.CreativeDooh ||
    datasetKey === EDatasetKey.CreativeDoohAdgroup
  )

  if (isReportingCampaign) {
    statuses = [
      ECampaignStatus.Active,
      ECampaignStatus.Inactive,
      ECampaignStatus.Disabled,
      ECampaignStatus.Completed,
    ]
  }
  if (isReportingCreative) {
    statuses = [
      ECampaignStatus.Active,
      ECampaignStatus.Inactive,
      ECampaignStatus.Disabled,
      ECampaignStatus.Pending,
      ECampaignStatus.Rejected,
    ]
  }

  let options = [
    {
      key: 'metric',
      name: intl.formatMessage({
        id: 'reporting.filter.filter.by.metric',
      }),
      popoverContent: <MetricFilter />,
    },
    {
      key: 'name',
      name: intl.formatMessage({
        id: 'reporting.filter.filter.by.name',
      }),
      popoverContent: <NameFilter />,
    },
    {
      key: 'status',
      name: intl.formatMessage({
        id: 'reporting.filter.filter.by.status',
      }),
      popoverContent: <StatusFilter statuses={statuses} />,
    },
    {
      key: 'channel',
      name: intl.formatMessage({
        id: 'reporting.filter.filter.by.channel',
      }),
      popoverContent: <ChannelFilter />,
    },
  ]

  // No channel as filter for the moment
  options = options.filter((option) => option.key !== 'channel')

  return options
}
