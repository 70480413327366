import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { ICategoryAttribution } from '../../../models/core'
import {
  StyledInput2,
  StyledButton,
  Container,
} from '../../../components/Global'
import I18NText from '../../../components/I18NText'

export const CategoryAttributionPopoverContent = ({
  categoryAttribution,
  handleCategoryAttributionSubmit,
  isUpdating,
  propertyToUpdate,
}: {
  categoryAttribution: ICategoryAttribution
  handleCategoryAttributionSubmit: (
    categoryAttribution: ICategoryAttribution
  ) => void
  isUpdating: boolean
  propertyToUpdate: 'postClickPurchases' | 'postViewPurchases'
}) => {
  const intl = useIntl()

  const defaultValues = categoryAttribution

  const onSubmit = (catAttribution: ICategoryAttribution) => {
    handleCategoryAttributionSubmit(catAttribution)
  }

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues,
  })
  return (
    <Container width="250px">
      <I18NText id={`${propertyToUpdate}.days`} preset="h6" fontWeight={500} />

      <Container
        width="100%"
        paddingTop="var(--gutter-2x)"
        marginBottom="var(--gutter-2x)"
      >
        <Controller
          name={propertyToUpdate}
          control={control}
          render={({ field }) => (
            <StyledInput2
              id={propertyToUpdate}
              field={field}
              error={errors[propertyToUpdate]}
              type="number"
              inputPadding={{
                paddingLeft: 'var(--gutter-1x)',
              }}
              onChange={(e) => {
                field.onChange(parseInt(e.target.value, 10))
              }}
              containerSize={{
                height: '40px',
                width: '100%',
              }}
            />
          )}
          rules={{
            validate: (value) => {
              let errMsgId = ''

              if (!value && value !== 0) {
                errMsgId = 'error.required'
              } else if (value < 1) {
                errMsgId = 'retailer.errors.min.one'
              }
              return errMsgId.length
                ? intl.formatMessage({
                    id: errMsgId,
                  })
                : true
            },
          }}
        />
      </Container>

      <StyledButton
        width="100%"
        disabled={!isValid || isUpdating}
        onClick={handleSubmit(onSubmit)}
      >
        <I18NText id="apply" />
      </StyledButton>
    </Container>
  )
}
