import styled from 'styled-components'
import { StyledButton } from '../Global'

export const StyledClearButton = styled(StyledButton)`
  && {
    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }
  }
`
