import { useMutation } from 'react-query'
import { IError } from '@unfoldrtech/portal-mic'
import { AxiosError, AxiosResponse } from 'axios'
import { generateCorrelationId } from '../utils/helpers/generateCorrelationId'
import { ICategoryAttributionUpdate } from '../models/core'
import { coreAPIV3 } from '../services/coreAPIV3'

export default function usePutCategoryAttribution(params: {
  retailerId: number
  categoryAttributionId: number
}) {
  return useMutation<
    AxiosResponse<void>,
    AxiosError<IError>,
    { payload: ICategoryAttributionUpdate }
  >(
    [
      `usePutCategoryAttribution_${params.retailerId}_${params.categoryAttributionId}`,
    ],
    ({ payload }) => {
      return coreAPIV3
        .retailerInventory()
        .putCategoryAttribution(
          params.retailerId,
          params.categoryAttributionId,
          generateCorrelationId(),
          payload
        )
    }
  )
}
