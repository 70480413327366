import { EAlignmentOptions } from '@unfoldrtech/portal-mic'
import React from 'react'
import { Container } from '../Global/containers'
import CallToAction from './CallToAction'
import { Text, TFontFamilyMixin } from '../Global'
import { TAdPreviewProps } from '../../models/types/TAdPreviewProps'

function TextElements({ adData, template, orientation }: TAdPreviewProps) {
  return (
    <Container
      maxWidth={`${template.data.dimensions.width}px`}
      maxHeight={`${template.data.dimensions.height}px`}
      overflow="hidden"
      display="flex"
      flexDirection="column"
      marginTop={
        orientation === 'portrait' &&
        adData.styling.alignment.textElements === EAlignmentOptions.Bottom
          ? 'var(--gutter-3x)'
          : ''
      }
    >
      <Container
        paddingTop="var(--gutter-3x)"
        paddingLeft="var(--gutter-3x)"
        paddingRight="var(--gutter-3x)"
        data-testid="ad-preview-headline-subline-container"
        flex="1 1 auto"
        textAlign={
          adData.styling.alignment.textElements === EAlignmentOptions.Right &&
          orientation === 'landscape'
            ? 'end'
            : undefined
        }
      >
        {template.data.textElements.maxCharacters.headline > 0 && (
          <>
            <Text
              preset="h2"
              fontSize={template.data.textElements.fontSize?.headline}
              fontFamily={
                template.data.textElements.fontFamily
                  ?.headline as TFontFamilyMixin
              }
              color={adData.styling.colors?.textElements}
              lineHeight={`${parseInt(
                template.data.textElements.fontSize?.headline || '',
                10
              )}px`}
              data-testid="ad-preview-headline"
            >
              {/* &nbsp; keeps the headline's space if its empty  */}
              {adData.headline || <>&nbsp;</>}
            </Text>
            <Container
              paddingTop={
                orientation === 'portrait' ? `var(--gutter-1x)` : undefined
              }
            />
          </>
        )}
        {template.data.textElements.maxCharacters.subline > 0 && (
          <Text
            preset="paragraph regular"
            fontSize={template.data.textElements.fontSize?.subline}
            fontFamily={
              template.data.textElements.fontFamily?.subline as TFontFamilyMixin
            }
            color={adData.styling.colors?.textElements}
            data-testid="ad-preview-subline"
            lineHeight={`${parseInt(
              template.data.textElements.fontSize?.subline || '',
              10
            )}px`}
          >
            {/* &nbsp; keeps the subline's space if its empty  */}
            {adData.subline || <>&nbsp;</>}
          </Text>
        )}
      </Container>
      {orientation === 'landscape' && (
        <Container
          paddingBottom="var(--gutter-3x)"
          paddingLeft="var(--gutter-3x)"
          paddingRight="var(--gutter-3x)"
          marginTop="auto"
          marginLeft={
            adData.styling.alignment.textElements === EAlignmentOptions.Right
              ? 'auto'
              : undefined
          }
        >
          <CallToAction template={template} adData={adData} />
        </Container>
      )}
    </Container>
  )
}

export default TextElements
