import { PayloadAction } from '@reduxjs/toolkit'
import { IRefreshChart } from '../../../models/interfaces'

const setRefreshChart = (
  state: IRefreshChart,
  action: PayloadAction<IRefreshChart>
) => {
  state.refreshChart = action.payload.refreshChart
}

export default setRefreshChart
