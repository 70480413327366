import { PayloadAction } from '@reduxjs/toolkit'
import { TReportingFilters } from '@unfoldrtech/portal-mic/dist/src/models/types'

const setStatus = (
  state: TReportingFilters,
  action: PayloadAction<{
    status?: string
  }>
) => {
  state.status = action.payload.status as TReportingFilters['status']
}

export default setStatus
