import setDealType from './setDealType'
import setAdGroupIds from './setAdGroupIds'
import setAggregationType from './setAggregationType'
import setCampaignIds from './setCampaignIds'
import setChannelType from './setChannelType'
import setEndDate from './setEndDate'
import setInventoryType from './setInventoryType'
import setSortBy from './setSortBy'
import setSortByV3 from './setSortByV3'
import setSortOrder from './setSortOrder'
import setStartDate from './setStartDate'
import setTimeProperties from './setTimeProperties'
import setTimeWindow from './setTimeWindow'
import setAggregationTypeV3 from './setAggregationTypeV3'
import setIsReportingV3 from './setIsReportingV3'
import setFilterBy from './setFilterBy'
import setFilterOperation from './setFilterOperation'
import setFilterValue from './setFilterValue'
import setName from './setName'
import setStatus from './setStatus'
import resetReportingCustomFilters from './resetReportingCustomFilters'

export default {
  setAggregationType,
  setAdGroupIds,
  setCampaignIds,
  setChannelType,
  setEndDate,
  setInventoryType,
  setSortBy,
  setSortByV3,
  setSortOrder,
  setStartDate,
  setTimeProperties,
  setTimeWindow,
  setDealType,
  setAggregationTypeV3,
  setIsReportingV3,
  setFilterBy,
  setFilterOperation,
  setFilterValue,
  setName,
  setStatus,
  resetReportingCustomFilters,
}
