import { useQuery } from 'react-query'
import { coreAPIV3 } from '../services/coreAPIV3'
import { generateCorrelationId } from '../utils/helpers'

type TParams = {
  retailerId: number
}

export default function useGetRetailerCategories({ retailerId }: TParams) {
  const queryKey = `getRetailerInventoryCategories${retailerId}`

  return useQuery([queryKey], () => {
    return coreAPIV3
      .retailerInventory()
      .getCategories(retailerId, generateCorrelationId())
  })
}
