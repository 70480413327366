import React, { useEffect, useRef, useState } from 'react'
import { OverlayTrigger } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { sanitizeDataTestId } from '../../utils'
import {
  StyledAntDropdown,
  StyledPopover,
  StyledAntMenu,
  SVGContainer,
  IconFilter,
  IconArrowUpSmall,
  IconArrowDownSmall,
  Container,
} from '../Global'
import I18NText from '../I18NText'
import {
  resetReportingCustomFilters,
  selectReportingFilters,
} from '../../store/reportingFilters'
import { useGetReportingFilterOptions } from '../../hooks/use-get-reporting-filter-options'
import { selectReportingData } from '../../store/reportingData'
import { ClearAllPill } from './PopoverFilters/Pills/ClearAllPill'
import { NamePill } from './PopoverFilters/Pills/NamePill'
import { StatusPill } from './PopoverFilters/Pills/StatusPill'
import { MetricPill } from './PopoverFilters/Pills/MetricPill'

// The product filter as initial base, eventually we should revamp/update the antd dropdown behaviour
export const ReportingFilter = () => {
  const dispatch = useDispatch()
  const { name, status, filterBy, filterOperation, filterValue } = useSelector(
    selectReportingFilters
  ) // add channel if we ever add it to the filters
  const datasetKey = useSelector(selectReportingData)
  const options = useGetReportingFilterOptions()
  const ref = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false)
  const [activePopoverContent, setActivePopoverContent] =
    useState<React.ReactNode>(<></>)

  useEffect(() => {
    setIsOpen(false)
    setIsOpenPopover(false)
  }, [name, status, filterBy, filterOperation, filterValue])

  useEffect(() => {
    dispatch(resetReportingCustomFilters({}))
  }, [datasetKey])

  const onClickPill = (optionKey: string) => {
    const activePopover = options.find(
      (option) => option.key === optionKey
    )?.popoverContent
    if (activePopover) {
      setIsOpen(true)
      setActivePopoverContent(activePopover)
      // this timeout is a workaround due to the popover contennt not being rendered otherwise
      setTimeout(() => {
        setIsOpenPopover(true)
      }, 100)
    }
  }

  return (
    <Container
      ref={ref}
      display="flex"
      cursor="pointer"
      fontFamily="Archivo-Regular"
      gap="var(--gutter-1x)"
    >
      <Container
        width="140px"
        data-testid="reporting-filter-trigger"
        onClick={() => {
          if (isOpen) {
            setIsOpen(false)
            setIsOpenPopover(false)
          } else {
            setIsOpen(true)
          }
        }}
      >
        <StyledAntDropdown
          getPopupContainer={(triggerNode) => triggerNode.parentElement!}
          open={isOpen}
          onOpenChange={(open) => {
            setIsOpen(open)
          }}
          overlayStyle={{
            minWidth: '180px',
          }}
          overlay={
            <OverlayTrigger
              rootClose
              show={isOpenPopover}
              onToggle={(show) => {
                setIsOpenPopover(show || isOpen)
              }}
              trigger="click"
              placement="right-start"
              container={ref.current}
              overlay={
                <StyledPopover
                  id="filter-popover"
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                  show={isOpenPopover}
                >
                  <StyledPopover.Body>
                    {activePopoverContent}
                  </StyledPopover.Body>
                </StyledPopover>
              }
            >
              <StyledAntMenu style={{ padding: '4px' }}>
                {options.map((option) => (
                  <StyledAntMenu.Item
                    onClick={({ domEvent }) => {
                      domEvent.stopPropagation()
                      setIsOpenPopover(true)
                      setActivePopoverContent(option.popoverContent)
                    }}
                    key={option.key}
                    data-testid={sanitizeDataTestId(
                      `reporting-filter-option-${option.key}`
                    )}
                  >
                    <Container fontFamily="Archivo-Regular">
                      <I18NText
                        id={option.name}
                        preset="Archivo-Regular"
                        fontSize="var(--font-size-pa)"
                        color="var(--font-color-default)"
                      />
                    </Container>
                  </StyledAntMenu.Item>
                ))}
              </StyledAntMenu>
            </OverlayTrigger>
          }
        >
          <Container
            display="flex"
            alignItems="center"
            gap="var(--gutter-1x)"
            padding="12px var(--gutter-2x)"
            border={{
              borderRadius: 'var(--gutter-6x)',
            }}
            backgroundColor={
              isOpen
                ? 'var(--background-color-active)'
                : 'var(--background-color-disabled)'
            }
            height="var(--font-size-h1)"
          >
            <SVGContainer>
              <IconFilter />
            </SVGContainer>
            <I18NText
              id="reporting.filter.select"
              fontSize="var(--font-size-ps)"
              color="var(--font-color-dark)"
            />
            <SVGContainer>
              {isOpen ? (
                <IconArrowUpSmall fill="var(--font-color-dark)" />
              ) : (
                <IconArrowDownSmall fill="var(--font-color-dark)" />
              )}
            </SVGContainer>
          </Container>
        </StyledAntDropdown>
      </Container>
      <NamePill onClick={() => onClickPill('name')} />
      <StatusPill onClick={() => onClickPill('status')} />
      <MetricPill onClick={() => onClickPill('metric')} />
      <ClearAllPill />
    </Container>
  )
}
