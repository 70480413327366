import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetReportingCustomFilters,
  selectReportingFilters,
} from '../../../../store/reportingFilters'
import I18NText from '../../../I18NText'
import { StyledClearButton } from '../../../ProductFilterWrapper/StyledClearButton'
import { setRefreshTable } from '../../../../store/refreshTable'
import { setRefreshChart } from '../../../../store/refreshChart'

export const ClearAllPill = () => {
  const dispatch = useDispatch()

  const {
    name: reportingName,
    status: reportingStatus,
    filterBy: reportingFilterBy,
    filterOperation: reportingFilterOperation,
    filterValue: reportingFilterValue,
  } = useSelector(selectReportingFilters)

  const onClearAll = () => {
    dispatch(resetReportingCustomFilters({}))
    dispatch(setRefreshTable({ refreshTable: true }))
    dispatch(setRefreshChart({ refreshChart: true }))
  }

  const shouldShowClearAll = !!(
    reportingName ||
    reportingStatus ||
    reportingFilterBy ||
    reportingFilterOperation ||
    reportingFilterValue
  )

  return shouldShowClearAll ? (
    <StyledClearButton
      variant="link"
      backgroundColor="transparent"
      lineHeight={1}
      onClick={onClearAll}
    >
      <I18NText
        id="reporting.filter.filter.pill.clear.all"
        data-testid="reporting-filter-clear-all-pill"
      />
    </StyledClearButton>
  ) : null
}
