import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Container, StyledButton, Text, StyledSelect2 } from '../../Global'
import I18NText from '../../I18NText'
import { ECampaignStatus, ECreativeStatus, EStatus } from '../../../models/core'
import { sanitizeDataTestId } from '../../../utils'
import {
  selectReportingFilters,
  setStatus,
} from '../../../store/reportingFilters'
import { setRefreshTable } from '../../../store/refreshTable'
import { setRefreshChart } from '../../../store/refreshChart'

export interface IStatusFilterForm {
  status?: EStatus | ECreativeStatus | ECampaignStatus // ECampaignStatus includes all
}

export const StatusFilter = ({
  statuses,
}: {
  statuses: Array<ECampaignStatus>
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { status: reportingStatus } = useSelector(selectReportingFilters)
  const [statusFilterModal, setStatusFilterModal] = useState<IStatusFilterForm>(
    {
      status: reportingStatus,
    }
  )

  const methods = useForm<IStatusFilterForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
  } = methods

  const onSubmit = async (data: IStatusFilterForm) => {
    dispatch(setStatus({ status: data.status }))
    dispatch(setRefreshTable({ refreshTable: true }))
    dispatch(setRefreshChart({ refreshChart: true }))
    reset()
  }

  return (
    <Container width="240px">
      <Container paddingLeft="var(--gutter-1x)" flex="1">
        <Text fontWeight={500}>
          <I18NText id="reporting.filter.select.status" preset="h6" />
        </Text>
      </Container>
      <Container paddingTop="var(--gutter-2x)" paddingBottom="var(--gutter-1x)">
        <Controller
          name="status"
          control={control}
          defaultValue={statusFilterModal.status}
          render={({ field }) => (
            <StyledSelect2
              id="status"
              {...field}
              value={statusFilterModal.status}
              padding="var(--gutter-1x)"
              error={errors.status}
              label={intl.formatMessage({
                id: 'reporting.filter.select.status.placeholder',
              })}
              options={statuses.map((st, index) => {
                return {
                  id: index,
                  label: intl.formatMessage({
                    id: st.toLowerCase(),
                  }),
                  value: st,
                  'data-testid': sanitizeDataTestId(`status-option-${st}`),
                }
              })}
              onChange={(value) => {
                setStatusFilterModal({ status: value as ECampaignStatus })
                field.onChange(value)
              }}
              data-testid="reporting-filter-status-select"
            />
          )}
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: 'error.required',
              }),
            },
          }}
        />
      </Container>
      <Container display="flex" justifyContent="right">
        <StyledButton
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
          width="100px"
          height="40px"
        >
          <Container
            display="grid"
            height="calc(40px - var(--gutter-2x))"
            padding="var(--gutter-mini)"
          >
            <I18NText
              id="reporting.filter.apply"
              preset="cta-sm"
              fontSize="var(--font-size-ps)"
              data-testid="reporting-filter-status-apply-button"
            />
          </Container>
        </StyledButton>
      </Container>
    </Container>
  )
}
