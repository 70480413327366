import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError } from '@unfoldrtech/portal-mic'
import { generateCorrelationId, getTimezone } from '../utils/helpers'
import {
  TDACampaignsReportChartFilters,
  TPPRetailerId,
  TSPCampaignsReportChartFilters,
} from '../models/types'
import { EReportingTimeWindow } from '../models'
import {
  EParamChannelTypeGlobal,
  ETimeBucketType,
  IReportingCampaignsChartResponse,
} from '../models/core'
import { coreAPIV3 } from '../services/coreAPIV3'

export default function useGetRetailerReportingCampaignsChartV3({
  retailerId,
  advertiserId,
  channelType,
  timeWindow,
  startDate,
  endDate,
  tzZone,
  aggregationType,
  filterBy,
  filterOperation,
  filterValue,
  name,
  status,
  enabled = true,
  suspense = true,
}:
  | {
      enabled?: boolean
      suspense?: boolean
    } & (TSPCampaignsReportChartFilters | TDACampaignsReportChartFilters) &
      TPPRetailerId) {
  const queryKeyName = `getRetailerReportingCampaignsChart_${channelType}_${retailerId}_${advertiserId}`

  return useQuery<
    AxiosResponse<IReportingCampaignsChartResponse>,
    AxiosError<IError>
  >(
    [queryKeyName, { start: startDate, end: endDate, channelType }],
    () => {
      return coreAPIV3
        .retailerReporting()
        .getRetailerCampaignsReportChartV3(
          retailerId,
          generateCorrelationId(),
          advertiserId,
          channelType as EParamChannelTypeGlobal,
          timeWindow as unknown as EReportingTimeWindow,
          startDate,
          endDate,
          tzZone || getTimezone(),
          aggregationType as ETimeBucketType,
          filterBy,
          filterOperation,
          filterValue,
          name,
          status
        )
    },
    { suspense, enabled }
  )
}
