import React, { useEffect, useMemo, useRef, useState } from 'react'
import { EAlignmentOptions, IDimensions } from '@unfoldrtech/portal-mic'
import styled from 'styled-components'
import { Container, GridContainer } from '../Global/containers'
import { StyledImage } from '../Global'
import TextElements from './TextElements'
import CallToAction from './CallToAction'
import {
  TAdPreviewContainerProps,
  TAdPreviewProps,
} from '../../models/types/TAdPreviewProps'
import { IScaledDimensions, getScaledDimensions } from '../../utils/helpers'
import { TOrientationOptions } from '../../models'

interface IScaledGridContainerProps {
  transform?: string
  backgroundImage?: string
  backgroundSize?: string
  backgroundPosition?: string
  backgroundColor?: string
}

const ScaledGridContainer = styled(GridContainer)<IScaledGridContainerProps>`
  transform-origin: 0 0;
  transform: ${({ transform }) => transform || ''};
  background-image: ${({ backgroundImage }) => backgroundImage || ''};
  background-size: ${({ backgroundSize }) => backgroundSize || ''};
  background-position: ${({ backgroundPosition }) => backgroundPosition || ''};
  background-color: ${({ backgroundColor }) => backgroundColor || ''};
`

const getInitialItemDimensions = (
  dimension: IDimensions,
  initialScale?: number
): IScaledDimensions => {
  let orientation: TOrientationOptions = 'landscape'

  const { width, height } = dimension

  if (height > width) {
    orientation = 'portrait'
  }
  return {
    width: `${width}px`,
    height: `${height}px`,
    scale: initialScale ?? 1,
    orientation,
  }
}

const AdDataGridItems = ({
  adData,
  template,
  orientation,
}: TAdPreviewProps) => {
  const isPortrait = orientation === 'portrait'
  const isLandscapeAndIsLeftAligned =
    orientation === 'landscape' &&
    adData.styling.alignment.textElements === EAlignmentOptions.Left
  const isLandscapeAndIsRightAligned =
    orientation === 'landscape' &&
    adData.styling.alignment.textElements === EAlignmentOptions.Right

  const isPotraitAndIsTopAligned =
    isPortrait &&
    adData.styling.alignment.textElements === EAlignmentOptions.Top
  const isPotraitAndIsBottomAligned =
    isPortrait &&
    adData.styling.alignment.textElements === EAlignmentOptions.Bottom

  return (
    <>
      {(isPotraitAndIsTopAligned || isLandscapeAndIsLeftAligned) && (
        <TextElements
          adData={adData}
          template={template}
          orientation={orientation}
        />
      )}
      <Container
        display="flex"
        justifyContent="center"
        alignItems="center"
        alignSelf="center"
        alignContent="center"
        height={orientation === 'landscape' ? '100%' : ''}
        width={orientation === 'portrait' ? '100%' : ''}
        paddingTop={isPotraitAndIsBottomAligned ? 'var(--gutter-5x)' : ''}
      >
        <Container
          height={`${template.data.packshot.dimensions?.height}px`}
          width={`${template.data.packshot.dimensions?.width}px`}
          display="flex"
          justifyContent="center"
          alignItems="center"
          alignSelf="center"
          alignContent="center"
          border={{
            borderStyle: adData.styling.packshotURL ? undefined : 'dashed',
            color: 'var(--border-color-dashed)',
            width: '1px',
          }}
        >
          <StyledImage
            maxHeight={`${template.data.packshot.dimensions?.height}px`}
            maxWidth={`${template.data.packshot.dimensions?.width}px`}
            src={adData.styling.packshotURL}
            cursor="default"
            data-testid="ad-preview-packshot"
          />
        </Container>
      </Container>
      {(isPotraitAndIsBottomAligned || isLandscapeAndIsRightAligned) && (
        <TextElements
          adData={adData}
          template={template}
          orientation={orientation}
        />
      )}
      {orientation === 'portrait' && (
        <Container display="flex" justifyContent="center">
          <Container paddingBottom="var(--gutter-3x)" marginTop="auto">
            <CallToAction adData={adData} template={template} />
          </Container>
        </Container>
      )}
    </>
  )
}

function AdPreview({
  adData,
  template,
  height,
  width,
  initialScale,
}: TAdPreviewContainerProps) {
  const containerDimensionsRef = useRef<HTMLDivElement>(null)

  const [scaledDimensions, setScaledDimensions] = useState<IScaledDimensions>(
    getInitialItemDimensions(template.data.dimensions, initialScale)
  )

  const containerDimensions: IDimensions | null = useMemo(() => {
    const isFixedDimensions = height.endsWith('px') && width.endsWith('px')

    if (isFixedDimensions) {
      return {
        width: `${width}px`,
        height: `${height}px`,
      }
    }

    if (containerDimensionsRef?.current) {
      return {
        width: `${containerDimensionsRef.current.offsetWidth}px`,
        height: `${containerDimensionsRef.current.offsetHeight}px`,
      }
    }
    return null
  }, [containerDimensionsRef?.current, width, height])

  useEffect(() => {
    if (containerDimensions) {
      const computedDimensions = getScaledDimensions(
        containerDimensions,
        getInitialItemDimensions(template.data.dimensions, initialScale)
      )

      setScaledDimensions(computedDimensions)
    }
  }, [containerDimensions, template.data.dimensions, setScaledDimensions])

  const { scale, orientation } = scaledDimensions

  const packshotWidth = template.data.packshot.dimensions?.width
  const packshotHeight = template.data.packshot.dimensions?.height
  const gridTemplateColumns =
    adData.styling.alignment.textElements === EAlignmentOptions.Right
      ? `${orientation === 'landscape' ? `${packshotWidth}px` : ''} 1fr`
      : `1fr ${orientation === 'landscape' ? `${packshotWidth}px` : ''}`

  const initialScaledDimensions: IDimensions = {
    width: `${+template.data.dimensions.width * scale}px`,
    height: `${+template.data.dimensions.height * scale}px`,
  }

  const adPreviewGridTemplateRows =
    adData.styling.alignment.textElements === EAlignmentOptions.Top
      ? `auto ${packshotHeight}px auto`
      : `${packshotHeight}px auto auto`

  return (
    <Container
      ref={containerDimensionsRef}
      // height={height}
      width={width}
      maxHeight={height}
      maxWidth={width}
      overflow="hidden"
    >
      <Container
        width={
          scaledDimensions.width === '0px'
            ? initialScaledDimensions.width
            : scaledDimensions.width
        }
        height={
          scaledDimensions.height === '0px'
            ? initialScaledDimensions.height
            : scaledDimensions.height
        }
      >
        <ScaledGridContainer
          display="grid"
          boxSizing="border-box"
          gridTemplateColumns={gridTemplateColumns}
          gridTemplateRows={
            orientation === 'portrait' ? adPreviewGridTemplateRows : ''
          }
          width={`${template.data.dimensions.width}px`}
          height={`${template.data.dimensions.height}px`}
          overflow="hidden"
          data-testid="ad-preview-background"
          transform={`scale(${scale})`}
          backgroundImage={
            adData.styling.backgroundImage?.url
              ? `url(${adData.styling.backgroundImage.url})`
              : undefined
          }
          backgroundSize={
            adData.styling.backgroundImage?.url ? 'cover' : undefined
          }
          backgroundPosition={
            adData.styling.backgroundImage?.url ? 'center' : undefined
          }
          backgroundColor={
            adData.styling.backgroundImage?.url
              ? undefined
              : adData.styling.colors?.adBackground
          }
        >
          <AdDataGridItems
            adData={adData}
            template={template}
            orientation={orientation}
          />
        </ScaledGridContainer>
      </Container>
    </Container>
  )
}
export default AdPreview
