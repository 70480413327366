import {
  EDayTimeTargetCriteria,
  EDayTimeTargetDay,
  EDayTimeTargetTime,
  IAdGroupCategoryOptionalTargetList,
  IAdGroupKeywordOptionalTargetList,
  IAdGroupPositionOptionalTargetList,
} from '@unfoldrtech/portal-mic'

export class CTransformAdGroupUpdate {
  productIdList: Array<number>

  targeting?:
    | IAdGroupKeywordOptionalTargetList
    | IAdGroupCategoryOptionalTargetList
    | IAdGroupPositionOptionalTargetList

  title?: string

  criteria?: EDayTimeTargetCriteria

  days?: EDayTimeTargetDay[] | null

  times?: EDayTimeTargetTime[] | null

  constructor(
    productIdList: Array<number>,
    targeting?:
      | IAdGroupKeywordOptionalTargetList
      | IAdGroupCategoryOptionalTargetList
      | IAdGroupPositionOptionalTargetList,
    title?: string,
    criteria?: EDayTimeTargetCriteria,
    days?: EDayTimeTargetDay[] | null,
    times?: EDayTimeTargetTime[] | null
  ) {
    this.targeting = targeting
    this.title = title
    this.productIdList = productIdList
    this.criteria = criteria
    this.days = days
    this.times = times
  }
}
