import { createSlice } from '@reduxjs/toolkit'
import { IRefreshChart } from '../../models/interfaces'
import reducers from './reducers'

const INITIAL_REFRESH_CHART_STATE: IRefreshChart = <IRefreshChart>{
  refreshChart: false,
}

export const refreshChartSlice = createSlice({
  name: 'refreshChart',
  initialState: INITIAL_REFRESH_CHART_STATE,
  reducers: {
    resetRefreshChart: () => INITIAL_REFRESH_CHART_STATE,
    ...reducers,
  },
})
export const { setRefreshChart } = refreshChartSlice.actions

export const refreshChartReducer = refreshChartSlice.reducer
