import { PayloadAction } from '@reduxjs/toolkit'
import { TReportingFilters } from '@unfoldrtech/portal-mic/dist/src/models/types'

const setName = (
  state: TReportingFilters,
  action: PayloadAction<{
    name?: string
  }>
) => {
  state.name = action.payload.name || undefined
}

export default setName
