import { TReportingFilters } from '@unfoldrtech/portal-mic/dist/src/models/types'

const resetReportingCustomFilters = (state: TReportingFilters) => {
  state.filterBy = undefined
  state.filterOperation = undefined
  state.filterValue = undefined
  state.name = undefined
  state.status = undefined
}

export default resetReportingCustomFilters
